<template>
     <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br>{{desc2}}</p>
                        <!-- <p class="mb-0">{{desc1}}<br>{{desc2}}&nbsp;&nbsp;&nbsp;&nbsp;<u v-b-modal.modal-3 variant="link" style="color:blue;">{{desc3}}</u></p>
                        <b-modal id="modal-3" size="xl" title="1688 주문서 업로드 방법" ok-title="Save Changes" cancel-title="Close">
                            <p>생성한 주문서를 <a href="https://www.1688.com" target="_blank"><u>1688</u></a>에 접속해서 아래의 순서로 업로드해주세요</p>
                            <img  style="max-width:100%;" :src="require('../../../assets/images/small/1688주문서업로드.png')">
                        </b-modal> -->
                    </div>
                </div>
                <tab-nav :tabs="true" id="myTab-1">
                    <tab-nav-items :active="true" id="list-tab" ariaControls="list" role="tab" :ariaSelected="false" title="구매대행요청내역" />
                    <!-- <tab-nav-items :active="true" id="req-tab" ariaControls="req" role="tab" :ariaSelected="true" title="구매대행요청" /> -->
                </tab-nav>

                <tab-content id="myTabContent">
                    <!-- <tab-content-item :active="true" id="req" aria-labelled-by="req-tab">
                        <div class="card">
                            <div class="card-header">
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio15" name="customRadio-4" class="custom-control-input" v-model="reqtype" value="로켓배송">
                                    <label class="custom-control-label" for="customRadio15">로켓배송</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio16" name="customRadio-4" class="custom-control-input" v-model="reqtype" value="제트배송">
                                    <label class="custom-control-label" for="customRadio16">제트배송</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio17" name="customRadio-4" class="custom-control-input" v-model="reqtype" value="일반LCL">
                                    <label class="custom-control-label" for="customRadio17">일반LCL</label>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <p>*기본검수 및 멀티구성(2p -> 1set)작업 등이 포함된 금액이며, <br>단가는 물량에 따라 조정될 수 있습니다.</p>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                <div class="custom-control custom-switch custom-control-inline">
                                                    <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="checkbarcode">
                                                    <label class="custom-control-label" for="customSwitch2">입출고 발주센터 분류 및 바코드라벨 부착 (스티커, ￦600)</label>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                <div class="custom-control custom-switch custom-control-inline">
                                                    <input type="checkbox" class="custom-control-input" id="customSwitch3" v-model="checksticker">
                                                    <label class="custom-control-label" for="customSwitch3">원산지작업 (스티커, ￦200)</label>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                <div class="custom-control custom-switch custom-control-inline">
                                                    <input type="checkbox" class="custom-control-input" id="customSwitch4" v-model="checkstamp">
                                                    <label class="custom-control-label" for="customSwitch4">원산지작업 (도장, ￦200)</label>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                <div class="custom-control custom-switch custom-control-inline">
                                                    <input type="checkbox" class="custom-control-input" id="customSwitch4" v-model="checktag">
                                                    <label class="custom-control-label" for="customSwitch4">원산지작업 (택총, ￦300)</label>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                <div class="form-group">
                                                    <label for="exampleFormControlTextarea1">기타요청사항</label>
                                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="reqcontent"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4" v-if="checkbarcode">
                                        <div class="row">
                                            <div class="text-center">
                                                <img style="width:80%;" :src="require('../../../assets/images/small/barcode.png')">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 mt-2">
                                        <p class="mb-0" @click="confirmexcelDownFunc"><u variant="link" style="color:blue;">구매대행 요청 양식 다운로드</u></p>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <b-button variant="primary" v-b-modal.modal-scrollable>파일 업로드</b-button>

                                        <b-modal id="modal-scrollable" ok-title="동의" cancel-title="거부" scrollable title="구매대행 주의사항" @ok="fileupload()">
                                            <p class="my-4">
                                                1] 구매대행 신청시 주의 사항<br><br>

                                                1. 구매대행 신청 시 입력해주시는 견적사항에 맞게 선결제를
                                                한 후 구매를 진행하며 해당 금액이 변동되어 추가금액이 발생할
                                                경우 차이 금액이 기존 금액의 50%미만이라면 구매 진행 후
                                                무게 측정 시 해당 비용만큼 추가비용으로 결제요청을 드립니다.
                                                50%가 초과한 경우 제품의 추가비용만큼 추가로 결제를 진행해주셔야
                                                하며 추가 결제를 완료 후 구매작업을 진행합니다.<br><br>

                                                2. 구매대행 진행 시 제품 재고 부족 등의 이유로 판매자가 배송을
                                                진행하지 않거나 지연되는 경우 해당 제품의 금액만큼 환급되며
                                                이 경우 환급 시 수수료는 제외됩니다.<br><br>

                                                3. 환급 시 해당 주의 고시환율을 기준으로 환율이 적용
                                                되며 예치금으로 전환 됩니다.<br><br>

                                                4. 구매대행 신청서 작성시 양식의 내용이 정확하지 않을 경우에는
                                                구매대행이 취소될 수 있습니다.<br><br>

                                                5. 구매대행은 온라인 상품에 대한 구매만을 이야기 하기 때문에
                                                해당 제품의 정품 여부 파손 오염 여부 등에 대하여 보장을
                                                하지 않습니다.<br><br>

                                                6. 신청서 작성 후 선 결제를 진행하지 않고 7일 이상 초과한
                                                경우 신청서는 자동 폐기처리됩니다.<br><br>

                                                7. 신청서의 오기재로 인한 주문 오류는 쿠플러스 및 쿠플러스 제휴 배송대행지에서
                                                책임을 지지 않습니다.<br><br>

                                                8. 신청서에서 작성되는 모든 정보의 경우 세관신고서에 반영되는
                                                정보이기 때문에 사용자가 정확하게 입력해주셔야 합니다.
                                                허위 정보 작성  정보 미 or 오기재로 인해 발생하는 불이익에
                                                대해서는 쿠플러스 및 쿠플러스 제휴 배송대행지에서 책임지지 않습니다.<br><br>

                                                10. 수입이 금지된 제품(마약/위험물품 등)의 제품 구매 및
                                                배송으로 인해 발생하는 불이익에 대하여 쿠플러스 및 쿠플러스 제휴 배송대행지에서 어떠한
                                                책임도 지지 않습니다.<br><br>

                                                11. 제품의 운송장번호(트래킹 번호)당 하나의 그룹으로 묶여서
                                                진행됩니다. 추후 배송지시  컨펌 등의 확인 및 배송 요청에
                                                대하여 그룹으로 움직이며 그룹을 해지하고자 하시는 경우는
                                                카카오톡 채널 고객센터를 통해 해지 요청을 해주셔야 합니다.
                                                그룹 해지 요청시 추가비용이 발생하며 분리 난이도에 따라
                                                비용이 발생할 수 있습니다.<br><br>

                                                12. 타오바오/알리바바 등에서 주문한 제품이 고객님의 문제로
                                                판매자와 논쟁이 발생할 경우 쿠플러스 및 쿠플러스 제휴 배송대행지에서 조언 등은
                                                가능하지만 책임을 지고 문제를 해결해 드릴 수는 없습니다.
                                                단, 판매자의 문제이거나 저희의 실수로 인해서 발생한 문제의
                                                경우에는 구매대행 담당자가 책임지고 문제를 해결해 드리도록
                                                하겠습니다.<br><br>

                                                13. 제품의 실사는 제품의 최소 포장 단위를 기준으로 촬영을
                                                해드립니다. 기본 실사 제공은 제품의 도착 여부만을 확인할
                                                수 있게 업로드 해드리며 제품의 수량  오염  파손  오배송
                                                등의 사항은 확인되지 않을 수 있습니다. 상세한 확인을
                                                원하시는 경우 제품 도착 확인 시 상세 검수를 신청하시기
                                                바랍니다.<br><br>

                                                14. 신청서 작성 입고 확인 시 요청하는 부가서비스의 경우
                                                동일한 서비스라도 입고 확인 시 요청하는 사항에 대해서
                                                비용이 다르게 책정 될 수 있습니다.<br><br>

                                                15. 신청서 작성 시 운송장 번호 오기재로 인한 입고
                                                미입고된 제품을 추후 운송장번호를 수정  변경 하여
                                                입고요청을 해주시는 경우는 추가비용이 발생할 수
                                                있습니다.<br><br>

                                                16. 제품의 분리 나눔 배송 요청은 기본 지원 해드리지
                                                않으며 요청을 하시고자 하는 경우 카카오톡 채널 고객센터를 통해 분리
                                                나눔하고자 하는 제품의 이미지와 수량을 명확하게 표기하여
                                                요청해주셔야하며 해당 작업으로 인하여 추가비용이
                                                발생할 수 있습니다.<br><br>

                                                17. 고객님께서 컨펌 이후 제품에 이상이 있어서 전 단계로
                                                변경을 원하시거나 해당 제품에 대한 재검수 반품 등을
                                                원하시는 경우 해당 건에 대하여 추가 작업은 불가능하니
                                                컨펌은 신중히 확인 후 진행하시기 바랍니다.<br><br>

                                                18. 실사 옵션 사항을 확인하지 않고 배송 요청을 해주셔서
                                                일어나는 오배송  배송 누락 등의 사태에 대하여 쿠플러스 및 쿠플러스 제휴 배송대행지에서
                                                어떠한 보상도 해드리지 않습니다.
                                                주의 부탁 드립니다.<br><br>

                                                19. 제품 배송 지시 시 기본 묶음으로 지원되는
                                                그룹(신청서)은 제한은 없으나 추후 센터 사정에 따라
                                                추가비용이 발생할 수 있습니다.<br><br>

                                                20. 포장은 배송지시를 하는 순간 무게측정이 되어있지
                                                않더라도 무게측정을 위한 포장준비단계 및 포장진행 중일
                                                수 있어 배송지시 전단계로 요청은 제한되니 배송지시는
                                                신중이 진행부탁드립니다. 참고로 포장진행은 배송지시한
                                                신청서의 모든 제품을 하나의 박스로 포장을 원칙으로 하나
                                                제품의 크기가 하나의 박스로 포장이 불가능한 경우
                                                멀티박스로 포장되며 해당 비용은 무게 혹은 부피에 따라 청구됩니다.<br><br>

                                                21. 중국 택배의 특성상 배송이 완료가 되었다고 해도 저희
                                                센터에 도착하는 경우 2-3일 간의 시간차가 있을 수 있기
                                                때문에 최종 수취확인 날짜 기준 최대 3일 이후에 입고가
                                                되지 않는 경우에 한해서 문의가 가능하며 수취확인이 된
                                                날짜 기준으로 3일 이하의 건들에 대해서는 답변을 해드리지
                                                않습니다.<br><br>

                                                22. 국내 택배의 규격 외 화물(부피가 크거나 무게가
                                                초과되는 경우) 등의 경우 기존 택배사가 아닌 대신, 경동
                                                택배로 변경되어 배송되며 운송장번호가 변경되고 추가 착불
                                                금액이 발생됩니다. 한진택배  불가로 인해
                                                화물택배(경동/대신)로 변경은 통관 후 택배사 인계 과정에서
                                                결정되는 사안으로 변경된 당일 운송장번호 확인은 어려우며
                                                변경된 번호 확인을 위해서는 직접 센터에 문의를 주셔야
                                                확인이 가능하니 이점 참고하시기 바랍니다.<br><br>

                                                23. 제품의 크기 및 무게가 과도하여 우체국 택배에서 인계되어
                                                화물 택배(경동,대신)로 이전되어 추가 비용이 발생하는 건에
                                                대해서 쿠플러스 및 쿠플러스 제휴 배송대행지에서는 책임을 지지 않습니다.<br><br>

                                                24. 결제 금액 불일치 입금자명이 주문인-수취인과 불일치할
                                                경우 입금 확인이 지연되거나 확인이 되지 않을 수 있습니다.
                                                <br><br>

                                                25. 제품이 저희 타오바바 배송대행지에 도착한 뒤 30일 까지는
                                                무료보관을 진행하며 30일 초과시 신청서 별로 1일당 1,000원의
                                                비용이 무게측정 시에 부과되며, 90일 이후에는 자동 폐기처리
                                                됩니다.노데이터 입고처리된 제품의 경우 30일 보관 이후 일괄
                                                폐기처리됩니다. 제품의 보관 중 분실 파손이 되는 경우는
                                                기본적으로 제품 금액만큼 환불처리를 해드리오나 최대 배상
                                                한도는 해당 제품을 작업했던 작업비용에 한합니다.
                                                <br><br>

                                                26. 입금 확인의 경우 실시간이 아닌 오전 오후 일괄 적용 되기
                                                때문에 사용자가 실시간 확인 시 지연이 있을 수 있습니다.
                                                <br><br>

                                                27. USD 150달러 이상 제품 가격을 신청해주시거나 식품
                                                목록통관 불가 상품등을 포함한 배송의 경우 자동으로
                                                간이통관으로 변경되는 것이 아닌 취하 대상이며 해당 경우
                                                관부가세 및 관세사 수수료 등의 추가 발생할 수 있으며 해당
                                                비용 발생에 대하여 쿠플러스 및 쿠플러스 제휴 배송대행지에서는 책임을 지지 않습니다.
                                                <br><br>

                                                28. 지적 재산권 침해 상품(가품,디즈니,레고 등의 브랜드 상품)의
                                                경우 통관 취하시 해당 제품은 무조건 폐기되시며 기타 제품을
                                                포함한 배송의 경우 지재권 제품 폐기  그 외 제품 분할 작업으로
                                                인하여 세관에서 분할/폐기에 대한 수수료가 발생할 수 있습니다.
                                                해당 비용 발생에 대하여 쿠플러스 및 쿠플러스 제휴 배송대행지에서는 책임을 지지 않습니다.
                                                <br><br>

                                                29. 제품명 오기재  품목 가격 고의 누락-제품 가격 수정 등으로
                                                인해 세관 통관 시 과태료(16만원가량)가 발생할 수 있으며 해당
                                                비용 발생에 대하여 쿠플러스 및 쿠플러스 제휴 배송대행지에서는 책임을 지지 않습니다.<br><br>


                                                2]해외 배송 보상 불가 규정에 대한 안내 사항에 동의하시겠습니까?
                                                <br><br>

                                                국제 배송의 특성상 여러 과정을 거치기 때문에 국내 수령 후 제품의
                                                파손에 대하여 배상은 불가합니다. 해당 제품이 당사 도착 시 도착한
                                                운송장 번호와 별도 요청 시 제품의 실사를 업로드 해드리며, 고객님께서 이상이
                                                없다고 판단하여 컨펌이 완료된 제품은 이상이 없는 것으로 간주하여
                                                추후 제품의 파손  오염 등의 사항이 발생하더라도 당사에서는
                                                책임을 지지 않습니다. 제품의 파손이 우려되신다면 제품 도착 후
                                                에어캡 포장 배송 지시 시 특수 포장 등의 포장 보완을 요청하시면
                                                최선을 다하여 포장 작업을 진행하며 제품의 부피 무게에 따라
                                                기본 비용 보다 추가로 발생하거나 작업이 거부될 수 있습니다.
                                                <br><br>

                                                예시) 간단한 제품 에어캡(뽁뽁이) 포장 : 기본 요금 발생, 제품의
                                                부피가 큰 제품(자전거, 봇 등) 포장 : 기본 요금 +@ 비용이
                                                발생하거나 작업이 거부될 수 있습니다.<br><br>


                                                3] 컨펌 이후 이전 단계로 되돌리기 불가에 대한 안내<br><br>

                                                당사에서는 제품의 최종 확인은 고객님이 컨펌을 완료된 후
                                                고객님께서 제품 배송요청을 해주셔야 포장 및 무게측정을
                                                진행합니다. 때문에 고객님께서 컨펌을 하신 후 제품의 추가 확인
                                                추가 사진 요청  반품 등의 요청사항은 진행이 불가능합니다.
                                                때문에 반드시 제품의 실사를 확인 하신 후 컨펌을 진행하시기
                                                바랍니다. <br><br>

                                                4] 한 운송장번호의 제품 그룹에 대한 동의 사항<br><br>

                                                당사에서는 한 운송장번호로 온 제품은 하나의 그룹으로 묶이어
                                                실사 및 제품 확인이 가능합니다. 한 운송장번호로 도착한 제품을
                                                나누고자 하시는 경우는 분리 요청을 해주셔야 하며 분리 요청의
                                                경우 신청서 수량이 2개 초과하여 분리 요청하는 경우 분리되는
                                                신청서 1개당 1,000원의 비용이 발생하며 분리 난이도에 따라
                                                추가비용이 발생할 수 있습니다. <br><br>


                                                5] 통관 보류, 취하에 대한 동의사항<br><br>

                                                a)목록 통관시 제품의 통관 보류,취하 된 경우 관부가세(관세+부가세)
                                                및 관세사 비용(33,000원)이 발생할 수 있으며, 제품의 종류에 따라
                                                추가 비용이 발생할 수 있으며 미 납부 시 제품 수령이 불가합니다.
                                                <br><br>

                                                b)캐릭터 상품  상표권  수량 과다 등의 문제로 인한 통관 보류에
                                                대해 당사에서는 책임을 지지 않습니다.해당 제품이 문제가 있는
                                                상품일 경우 관세사 비용 및 관부가세 등 수수료가 발생할 수 있기
                                                때문에 주문하기 전 문제가 있는 제품인지 확인을 해보시기 바랍니다.
                                                <br><br>

                                                c)식품,식물 제품 등의 검역이 필요하여 간이통관이 필요한 제품을
                                                목록통관을 진행하신 경우 검역 비용 및 관세사 비용이 발생할 수
                                                있으며 이를 미납부 시 제품 수령이 불가하니 참고 바랍니다.
                                                <br><br>

                                                d)전자 제품은 1인당 1개씩만 진행이 가능하며 2개 이상 통관 시 1개의
                                                제품만 통관이 되며 나머지 제품은 폐기될 수 있습니다.<br><br>

                                                e)제품 품명 오기재  금액을 다르게 기재 등으로 인하여 세관에 통관
                                                진행 시 과태료가 발생할 수 있습니다.(드래그 작성 및 간편 작성의
                                                경우 고객님의 편의를 위해 제공할 뿐 오류 사항이 존재할 수 있습니다.
                                                반드시 신청서 작성 전  금액  수량  품명 등을 다시 한번 확인하시고
                                                수정해주시기 바랍니다.)<br><br>

                                                6. 오류입고 사항에 대한 안내 <br><br>

                                                제품의 오류 메시지 및 오류입고 진행의 경우 무료로 제공되는 서비스
                                                일 뿐이며 제품의 특성상 오류 메시지 및 오류 입고가 누락될 수 있습니다.
                                                해당 문제를 확인하시기 위해 제품의 실사 확인 및 컨펌 전 부가서비스
                                                요청을 통해 제품을 확인하실 수 있습니다. 오류가 없더라도 제품의 실사
                                                등을 반드시 확인해주시기 바랍니다.<br><br>

                                                7. 멀티박스 포장 및 부피과다한 제품 무게비용에 대한 안내 <br><br>

                                                배송지시 시 제품의 부피가 커서 한 박스로 포장이 불가능한 경우 자동으로
                                                멀티박스로 포장을 진행합니다. 멀티박스로 포장되는 경우 추가 박스당
                                                2,000원의 추가비용이 발생하며 무게측정 시 추가 청구 됩니다.제품이
                                                배송센터 도착 시 이미 큰 박스로 도착한 경우 기본 포장으로 배송이 됩니다.
                                                <br><br>

                                                8. 무료 사진 촬영 1회에 대한 안내 <br><br>

                                                무료 기본 실사 서비스는 1회 한정으로 진행되며 신청서 작성 시 기재한
                                                운송장번호를 기준으로 제품이 도착하였다는 것을 확인하기 위한 목적으로
                                                사진을 제공해드리며 색상  사이즈  오염  파손 등의 여부를 확인하는 데에는
                                                제대로 제공되지 않을 수 있습니다. 해당 색상 사이즈  오염  파손 등을
                                                확인하고자 하시는 경우는 상세 검수를 요청하시기 바라며 상세 검수는 제품의
                                                사이즈 및 작업 난이도에 따라 기본 수수료에 추가로 비용이 발생할 수 있습니다.
                                                <br><br>

                                                9. 화물 택배 인계 시 착불 비용 발생에 대한 동의사항 <br><br>

                                                한변의 길이가 100CM 이상  무게가 20kg 이상일 경우 한진 택배가 아닌 화물
                                                택배(대신/경동)로 변경되어 배송이 될 수 있습니다. 화물 택배로 발송이 되는
                                                경우 착불 비용이 발생하며 부피  무게에 대한 요금으로 자세한 사항은 대신
                                                택배/ 경동 택배 등 해당 화물택배 요금 규정에 따라 발생합니다. <br><br>

                                                10. 폐기 신청한 신청서 복구 불가에 대한 안내<br><br>

                                                폐기란 말 그대로 신청서는 폐기처리가 되며 이후 상태에서 어떠한 경우에도
                                                복구가 불가능합니다. 때문에 신청서를 폐기한 경우 폐기 후 재입고 요청
                                                제품의 추가 확인 등의 작업은 불가능 하기 때문에 폐기 진행 시 신중하게
                                                선택 하시기 바랍니다. <br><br>


                                                11. 반품 신청 시 수수료 발생 및 운송료 선불 지급에 대한 안내 <br><br>

                                                반품 신청 시 반품 수수료(2,000원)이 발생하며[구매대행 제외] 제품의
                                                운송료는 착불로 적용 시 판매자가 수취 거부하는 경우가 많아 제품의
                                                운송료는 고객님께서 운송료를 선불로 입금 해 주셔야 하며 판매자에게
                                                이야기 하여 해당 제품의 배송료에 대하여 환불 요청을 하실 수 있습니다.
                                                (당사에서 반송 시 진행하는 택배사의 비용과 판매자가 환불해주는
                                                배송료에 차이가 있을 수 있습니다.) <br><br>

                                                12. 확인 요청사항에 대한 검수 및 자동 비용 청구에 대한 안내 <br><br>

                                                고객님들께서 확인요청 사항 혹은 1:1게시판에 문의주시는 내용
                                                (사이즈  색상  오염 등의 확인) 등의 경우 임의로 상세 검수 등으로
                                                인지하여 우선 처리되며 해당 비용이 무게측정 시 자동으로 청구 됩니다.
                                                해당 요청으로 인한 추가 비용에 대하여 철회 및 취소가 불가함을 안내드립니다.
                                                <br><br>
                                            </p>
                                        </b-modal>
                                    </div>
                                    <div class="col-md-12 d-none">
                                        <div class="form-group">
                                            <div class="custom-file">
                                            <input ref="pdbupload" type="file" @change="uploadfile" class="custom-file-input" id="inputGroupFile02">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12" v-if="showlist">
                            <div class="table-responsive rounded mb-3">
                                <tableList>
                                    <template v-slot:header>
                                        <tr class="ligth ligth-data">
                                            <th>
                                                <div class="checkbox d-inline-block">
                                                    <input type="checkbox" class="checkbox-input" id="checkbox1" v-model="allchecked" @change="allchecklist(allchecked)">
                                                    <label for="checkbox1" class="mb-0"></label>
                                                </div>
                                            </th>
                                            <th>제품정보</th>
                                            <th>상품매칭</th>
                                            <th style="max-width:300px;overflow:hidden;text-overflow: ellipsis;">구매링크</th>
                                            <th>옵션1_중국어</th>
                                            <th>옵션2_중국어</th>
                                            <th>구매요청수량</th>
                                            <th>Actions</th>
                                        </tr>
                                    </template>
                                    <template v-slot:body>
                                        <tr v-for="(list,index) in lists" :key="index">
                                            <td>
                                                <div class="checkbox d-inline-block">
                                                    <input type="checkbox" class="checkbox-input" id="checkbox2" v-model="list.checked">
                                                    <label for="checkbox2" class="mb-0"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <img :src="list.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                    <div>
                                                        {{list.상품명}}
                                                        <p class="mb-0"><small>{{list.SKUID}}</small></p>
                                                        <p class="mb-0"><small>{{list.바코드}}</small></p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td><div :class="matchclass(list.구매링크 == undefined || list.옵션1_중국어 == undefined || list.옵션2_중국어 == undefined ? '매칭필요' : '매칭완료')">{{list.구매링크 == undefined || list.옵션1_중국어 == undefined || list.옵션2_중국어 == undefined ? '매칭필요' : '매칭완료'}}</div></td>
                                            <td style="max-width:300px;overflow:hidden;text-overflow: ellipsis;">
                                                <div v-if="list.구매링크 !== undefined">
                                                    {{list.구매링크}}
                                                    <div v-if="list.showspinner">
                                                        <img style="width:20%;" :src="require('../../../assets/images/small/loading.gif')">
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <input @change="matchoption1(index,list.구매링크)" type="text" class="form-control" placeholder="구매링크입력" v-model="list.구매링크">
                                                </div>
                                            </td>
                                            <td>
                                                <div v-if="list.옵션1_중국어 !== undefined">
                                                    {{list.옵션1_중국어}}
                                                </div>
                                                <div v-else>
                                                    <b-dropdown class="my-class" size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                                                        <template #button-content>
                                                        &#x1f50d;<span class="sr-only">Search</span>
                                                        </template>
                                                        <b-dropdown-item href="#" v-for="a,i in list.상품매칭.옵션1_중국어" :key="i" @click="option1matching(a,list)">
                                                            <div class="d-flex align-items-center">
                                                                <img v-if="a.imageUrl.length > 0" :src="a.imageUrl" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                <div>
                                                                    {{a.name}}
                                                                </div>
                                                            </div>
                                                        </b-dropdown-item>
                                                    </b-dropdown>
                                                </div>
                                            </td>
                                            <td>
                                                <div v-if="list.옵션2_중국어 !== undefined">
                                                    {{list.옵션2_중국어}}
                                                </div>
                                                <div v-else>
                                                    <b-dropdown class="my-class" size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                                                        <template #button-content>
                                                        &#x1f50d;<span class="sr-only">Search</span>
                                                        </template>
                                                        <b-dropdown-item href="#" v-for="a,i in list.상품매칭.옵션2_중국어" :key="i" @click="option2matching(a,list)">
                                                            <div class="d-flex align-items-center">
                                                                <img v-if="a.imageUrl.length > 0" :src="a.imageUrl" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                <div>
                                                                    {{a.name}}
                                                                </div>
                                                            </div>
                                                        </b-dropdown-item>
                                                    </b-dropdown>
                                                </div>
                                            </td>
                                            <td>{{list.구매요청수량}}</td>
                                            <td>
                                                <div class="d-flex align-items-center list-action">
                                                    <a class="badge bg-success mr-2" data-toggle="tooltip" v-b-tooltip.top title="옵션재매칭" href="javascript:void(0)" @click="rematching(list,index)">
                                                        <i class="ri-pencil-line mr-0"></i>
                                                    </a>
                                                    <a class="badge badge-info mr-2" data-toggle="tooltip" v-b-tooltip.top title="Reset" href="javascript:void(0)" @click="reset(list)">
                                                        <i class="ri-eye-line mr-0"></i>
                                                    </a>
                                                    <a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="삭제" href="javascript:void(0)" @click="deleteitem(index)">
                                                        <i class="ri-delete-bin-line mr-0"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tableList>
                            </div>
                        </div>
                        <div class="col-lg-12 mb-4" v-if="showlist">
                            <button type="button" class="btn btn-primary mr-2" @click="requestpurchase">구매대행요청</button>
                            <button type="button" class="btn btn-secondary mr-2" @click="saveproductdb">상품DB에 저장</button>
                        </div>
                    </tab-content-item> -->



                    <tab-content-item :active="true" id="list" aria-labelled-by="list-tab">
                        <card>
                            <div class="p-3">
                                <form  @submit.prevent="submit" >
                                    <div class="row">

                                        <div class="col-md-12">
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadio7" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="today" @change="changedate()">
                                                <label class="custom-control-label" for="customRadio7">오늘</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadio8" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="week" @change="changedate()">
                                                <label class="custom-control-label" for="customRadio8">최근7일</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadio9" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="month" @change="changedate()">
                                                <label class="custom-control-label" for="customRadio9">최근1개월</label>
                                            </div>
                                            <!-- <div class="custom-control custom-switch custom-control-inline">
                                                <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="checksstock">
                                                <label class="custom-control-label" for="customSwitch2">사입제품 포함 발주서만 보기</label>
                                            </div> -->
                                        </div>
                                        <div class="col-md-12 mt-2">
                                            <b-input-group>
                                                <select class="custom-select col-md-2" style="height:40px" v-model="selectoption">
                                                    <option>요청일</option>
                                                </select>
                                                <b-form-datepicker id="example-datepicker1" size="sm" v-model="startdate" class="mb-2"></b-form-datepicker>
                                                <p>~</p>
                                                <b-form-datepicker id="example-datepicker2" size="sm" v-model="enddate" class="mb-2"></b-form-datepicker>

                                            </b-input-group>
                                        </div>
                                        <div class="col-md-3">
                                            <b-form-input type="text" class="form-control" list="my-list-id" placeholder="업체ID" @keyup.enter="importtextchange($event)" v-model="업체명"></b-form-input>
                                            <datalist id="my-list-id">
                                                <option v-for="companyid in companyids" :key="companyid">{{ companyid }}</option>
                                            </datalist>
                                        </div>
                                        <div class="col-md-3 d-flex align-items-center">
                                            <div class="custom-control custom-switch custom-control-inline">
                                                <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="tobepurchase">
                                                <label class="custom-control-label" for="customSwitch1">구매전인 상품만 조회</label>
                                            </div>
                                        </div>

                                    </div>
                                    <button type="button" class="btn btn-primary mr-2 mt-1" @click="search">검색</button>
                                    <img v-if="showspinner"  style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
                                </form>
                            </div>
                        </card>

                       <div class="col-lg-12" v-if="showreqlist">
                           <div class="p-3">
                               <div class="row">
                                   <b-button variant="outline-primary" class="mb-1 mr-2" @click="showtotal">
                                       전체 <b-badge variant="primary" class="ml-2">{{total}}</b-badge>
                                   </b-button>
                                   <b-button variant="outline-secondary" class="mb-1 mr-2" @click="showa">
                                       구매전<b-badge variant="secondary" class="ml-2">{{구매전}}</b-badge>
                                   </b-button>
                                   <b-button variant="outline-primary" class="mb-1 mr-2" @click="showj">
                                       구매확인<b-badge variant="primary" class="ml-2">{{구매확인}}</b-badge>
                                   </b-button>
                                   <b-button variant="outline-danger" class="mb-1 mr-2" @click="showf">
                                       구매취소<b-badge variant="danger" class="ml-2">{{구매취소}}</b-badge>
                                   </b-button>
                                   <b-button variant="outline-secondary" class="mb-1 mr-2" @click="showb">
                                       구매완료<b-badge variant="secondary" class="ml-2">{{발송전}}</b-badge>
                                   </b-button>
                                   <b-button variant="outline-danger" class="mb-1 mr-2" @click="showg">
                                       발송지연<b-badge variant="danger" class="ml-2">{{발송지연}}</b-badge>
                                   </b-button>
                                   <b-button variant="outline-secondary" class="mb-1 mr-2" @click="showc">
                                       발송완료<b-badge variant="secondary" class="ml-2">{{발송완료}}</b-badge>
                                   </b-button>
                                   <b-button variant="outline-danger" class="mb-1 mr-2" @click="showh">
                                       배송지연<b-badge variant="danger" class="ml-2">{{배송지연}}</b-badge>
                                   </b-button>
                                   <b-button variant="outline-primary" class="mb-1 mr-2" @click="showd">
                                       입고완료<b-badge variant="primary" class="ml-2">{{입고완료}}</b-badge>
                                   </b-button>
                               </div>
                               <div class="row mt-4">
                                   <b-button variant="outline-secondary" class="mb-1 mr-2" @click="showi">
                                       일반배송<b-badge variant="secondary" class="ml-2">{{일반배송}}</b-badge>
                                   </b-button>
                                   <b-button variant="outline-success" class="mb-1 mr-2" @click="showe">
                                       제트배송<b-badge variant="success" class="ml-2">{{노마드클럽}}</b-badge>
                                   </b-button>
                               </div>
                           </div>
                            <CDataTable :headers="headers" :items="reqlists" v-model="tableSelectedItem" :show-select="true" itemKey="_id">
                                <template v-slot:요청일="{item}">
                                    {{getTimeDate(item.요청일)}}
                                </template>
                                <template v-slot:묶음구성여부="{item}">
                                    {{item.묶음구성여부 ? item.묶음구성수량 + '개' : ""}}<br><small>{{item.묶음구성여부 ? '(' + item.바코드 + ")" : ""}}</small>
                                </template>
                                <template v-slot:상품명="{item}">
                                    <div class="d-flex align-items-center" style="padding: 20px">
                                        <img :src="item.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                        <div>
                                            {{item.상품명}}
                                            <p class="mb-0" style="color: #676E8A"><small>{{item.SKUID}}</small></p>
                                            <p class="mb-0" style="color: #676E8A"><small>{{item.바코드}}</small></p>
                                        </div>
                                    </div>
                                </template>
                                <template v-slot:구매링크="{item}">
                                    <a :href="item.구매링크" target="_blank"><b-icon icon="link" scale="2" variant="secondary"></b-icon></a>
                                </template>
                                <template v-slot:action="{item}">
                                    <div class="d-flex align-items-center list-action">
                                        <a v-if="changeoption" class="badge bg-success mr-2" data-toggle="tooltip" v-b-tooltip.top title="옵션재매칭" href="javascript:void(0)" @click="rematching(item)" style="width: 30px; height: 30px; display: flex; justify-content: center; align-items: center">
                                            <i class="ri-pencil-line mr-0"></i>
                                        </a>
                                        <a class="badge badge-info mr-2" data-toggle="tooltip" v-b-tooltip.top title="편집" href="javascript:void(0)" v-b-modal="modalID(item._id)" style="width: 30px; height: 30px; display: flex; justify-content: center; align-items: center">
                                            <i class="ri-eye-line mr-0"></i>
                                        </a>
                                        <b-modal :id="'modal' + item._id" @shown="showModal(item)" size="sm" title="구매요청사항" ok-title="Save Changes" cancel-title="Close" @ok="saverequest(formData)">
                                            <div class="row">
                                                <h6>1. 구매요청수량</h6>
                                                <div class="custom-control-inline ml-3">
                                                    <input type="number" :min="0" class="form-control" placeholder="" aria-label="" v-model="formData.구매요청수량" style="text-align:center;">
                                                </div>
                                            </div>
                                            <div class="row" v-if="formData.reqtype == '제트배송'">
                                                <div class="col-md-12 mt-4 mb-1 d-flex align-items-center justify-contents-start">
                                                    <h6>1. 구매요청수량</h6>
                                                    <div class="custom-control-inline ml-3">
                                                        <input type="number" :min="0" class="form-control" placeholder="" aria-label="" v-model="item.구매요청수량" style="text-align:center;">
                                                    </div>
                                                </div>

                                                <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                                    <h6>2. 판매구성수량</h6>
                                                    <div class="custom-control-inline ml-3">
                                                        <input type="number" :min="0" class="form-control" placeholder="" aria-label="" v-model="item.판매구성수량" style="text-align:center;" v-b-tooltip.left="'해당 SKU가 2p 구성일 경우에는 2라고 작성해 주세요'">
                                                    </div>
                                                </div>

                                                <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                                    <h6>3. A-B번들 묶음구성여부</h6>
                                                    <div class="custom-control ml-3 custom-switch custom-control-inline d-flex align-items-center">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch111" v-model="item.묶음구성여부" @change="confirmBarcode(item)">
                                                        <label class="custom-control-label" for="customSwitch111">&nbsp;</label>
                                                        <input v-if="item.묶음구성여부" type="number" :min="0" class="form-control ml-3" placeholder="종류갯수" aria-label="" v-model="item.묶음구성수량" style="text-align:center;" v-b-tooltip.bottom="'3종의 제품을 묶음 구성하는 경우 3으로 작성'">
                                                        <!-- <input v-if="item.묶음구성여부" type="text" class="form-control ml-3" placeholder="묶음대상바코드번호" aria-label="" v-model="item.바코드" style="text-align:center;" v-b-tooltip.bottom="'묶음구성할 대상의 바코드를 입력해 주세요.'"> -->
                                                    </div>
                                                </div>

                                                <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                                    <h6>4. 도착지</h6>
                                                    <div class="custom-control custom-radio custom-control-inline ml-3">
                                                        <input type="radio" id="customRadio3" name="customRadio-3" class="custom-control-input" v-model="item.요청내역.destination" value="coupang">
                                                        <label class="custom-control-label" for="customRadio3">쿠팡센터</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="customRadio4" name="customRadio-3" class="custom-control-input" v-model="item.요청내역.destination" value="customer">
                                                        <label class="custom-control-label" for="customRadio4">화주</label>
                                                    </div>
                                                </div>

                                                <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                                    <h6>5. 출고형태</h6>
                                                    <div class="custom-control custom-radio custom-control-inline ml-3">
                                                        <input type="radio" id="customRadio2" name="customRadio-44" class="custom-control-input" v-model="item.요청내역.exportType" value="box">
                                                        <label v-if="!item.요청내역.destination || item.요청내역.destination == 'coupang'" class="custom-control-label" for="customRadio2">밀크런 택배</label>
                                                        <label v-if="item.요청내역.destination == 'customer'" class="custom-control-label" for="customRadio2">택배</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="customRadio1" name="customRadio-44" class="custom-control-input" v-model="item.요청내역.exportType" value="pallet">
                                                        <label v-if="!item.요청내역.destination || item.요청내역.destination == 'coupang'" class="custom-control-label" for="customRadio1">밀크런 파렛트 (￦35,000/pallet)</label>
                                                        <label v-if="item.요청내역.destination == 'customer'" class="custom-control-label" for="customRadio1">파렛트 (￦20,000/pallet)</label>
                                                    </div>
                                                </div>



                                                <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                                    <h6>6. 통관정보</h6>
                                                    <div class="custom-control-inline ml-3">
                                                        <input type="text" class="form-control" placeholder="신고영문명" aria-label="" v-model="item.신고영문명" style="text-align:center;" v-b-tooltip.bottom="'제품의 신고영문명을 간단히 작성해주세요. ex) 신고영문명 : shoes'">
                                                    </div>
                                                    <div class="custom-control-inline">
                                                        <input type="text" class="form-control" placeholder="HS-CODE" aria-label="" v-model="item.HSCODE" style="text-align:center;" v-b-tooltip.bottom="'통관할 제품의 HS-CODE를 작성해주세요 ex) HS-CODE : 6401.10-1000'">
                                                    </div>
                                                    <a href="https://www.bandtrass.or.kr/hsnavi.do?page=F&site=" target="_blank"><u>HS-CODE 검색</u></a>
                                                </div>


                                                <div class="col-md-12 mt-3">
                                                    <h6>7. 검수 및 포장</h6>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch6" v-model="item.요청내역.default">
                                                        <label class="custom-control-label" for="customSwitch6">기본검수/분류/포장/포장자재 (￦200)</label>
                                                    </div>
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="item.요청내역.barcode">
                                                        <label class="custom-control-label" for="customSwitch2">바코드라벨 (Made in China,한글표시사항 기본포함) (￦100)</label>
                                                    </div>
                                                    <!-- <div class="custom-control custom-switch custom-control-inline" v-if="item.요청내역.barcode">
                                                        <input type="text" class="form-control" placeholder="바코드입력" aria-label="" v-model="item.바코드" style="text-align:center;" >
                                                    </div> -->
                                                </div>

                                                <div class="col-md-12 mt-0" v-if="item.요청내역.default">
                                                    <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'포장 방법을 쿠패스에 위임해서 진행함. (적합하지 않는 경우 추후 사입 시에는 얼마든지 변경가능하며, 판단 미스에 대한 별도의 보상은 없음.)'">
                                                        <input type="radio" id="customRadio88" name="customRadio-4" class="custom-control-input" value="auto" v-model="packageMethod" @change="changePackage(item)">
                                                        <label class="custom-control-label" for="customRadio88">포장 방법 위임</label>
                                                    </div>
                                                    <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'상품의 원상태 포장상태 그대로 진행'">
                                                        <input type="radio" id="customRadio5" name="customRadio-4" class="custom-control-input" value="noPackage" v-model="packageMethod" @change="changePackage(item)">
                                                        <label class="custom-control-label" for="customRadio5">재포장 생략</label>
                                                    </div>
                                                    <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'상품상태가 보이는 투명 OPP 봉투로 재포장 진행'">
                                                        <input type="radio" id="customRadio6" name="customRadio-4" class="custom-control-input" value="opp" v-model="packageMethod" @change="changePackage(item)">
                                                        <label class="custom-control-label" for="customRadio6">투명 OPP 봉투 포장 (무료)</label>
                                                    </div>
                                                    <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'상품상태가 보이지 않는 불투명 LDPE 봉투로 재포장 진행'">
                                                        <input type="radio" id="customRadio77" name="customRadio-4" class="custom-control-input" value="LDPE" v-model="packageMethod" @change="changePackage(item)">
                                                        <label class="custom-control-label" for="customRadio77">불투명 LDPE 봉투 포장 (무료)</label>
                                                    </div>

                                                </div>
                                                <div class="col-md-12 mt-0">
                                                    <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'박스 겉면에 있는 중국어를 가리는 작업 진행'">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch91" v-model="item.요청내역.masking">
                                                        <label class="custom-control-label" for="customSwitch91">중국어 마스킹 (무료)</label>
                                                    </div>
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch9" v-model="item.요청내역.airpacking">
                                                        <label class="custom-control-label" for="customSwitch9">에어캡 포장 (실비)</label>
                                                    </div>

                                                </div>

                                                <div class="col-md-12 mt-3 mb-1 d-flex align-items-center justify-contents-start">
                                                    <h6>8. 한글표시사항</h6>
                                                </div>
                                                <div class="col-md-12 mt-1 mb-1">
                                                    <div class="custom-control-inline w-50 d-flex align-items-center mb-2">
                                                        <label class="w-50 ml-0">바코드번호</label>
                                                        <input type="text" class="form-control" placeholder="수입원/판매원" aria-label="" v-model="item.바코드" style="text-align:center;"  v-b-tooltip.right="'ex) S00012341234'">
                                                    </div>
                                                    <div class="name-input">
                                                        <div class="custom-control-inline w-50 d-flex align-items-center">
                                                            <label class="w-50 ml-0">제품명</label>
                                                            <input type="text" class="form-control" placeholder="제품명" aria-label="" v-model="item.상품명" style="text-align:center;"  v-b-tooltip.right="'ex) 쿠플러스 예쁜접시, 10인치'">
                                                        </div>
                                                        <div class="warning2" style="font-weight: 500">* 쿠팡에 등록한 상품명 + 옵션명을 같이 입력해주세요</div>
                                                    </div>
                                                    <div class="custom-control-inline w-50 d-flex align-items-center">
                                                        <label class="w-50 ml-0">수입원/판매원</label>
                                                        <input type="text" class="form-control" placeholder="수입원/판매원" aria-label="" v-model="item.한글표시사항['수입원/판매원']" style="text-align:center;"  v-b-tooltip.right="'ex) 회사명'">
                                                    </div>
                                                    <div class="custom-control-inline w-50 d-flex align-items-center">
                                                        <label class="w-50 ml-0">제조원</label>
                                                        <input type="text" class="form-control" placeholder="제조원" aria-label="" v-model="item.한글표시사항.제조원" style="text-align:center;"  v-b-tooltip.right="'ex) 회사명OEM'">
                                                    </div>
                                                    <div class="custom-control-inline w-50 d-flex align-items-center">
                                                        <label class="w-50 ml-0">제조국</label>
                                                        <input type="text" class="form-control" placeholder="제조국" aria-label="" v-model="item.한글표시사항.제조국" style="text-align:center;"  v-b-tooltip.right="'ex) 중국'">
                                                    </div>
                                                    <div class="custom-control-inline w-50 d-flex align-items-center">
                                                        <label class="w-50 ml-0">내용량</label>
                                                        <input type="text" class="form-control" placeholder="내용량" aria-label="" v-model="item.한글표시사항.내용량" style="text-align:center;"  v-b-tooltip.right="'ex) 300ml'">
                                                    </div>
                                                    <div class="custom-control-inline w-50 d-flex align-items-center">
                                                        <label class="w-50 ml-0">원료명및성분명</label>
                                                        <input type="text" class="form-control" placeholder="원료명및성분명(재질)" aria-label="" v-model="item.한글표시사항['원료명및성분명(재질)']" style="text-align:center;"  v-b-tooltip.right="'ex) 도자기제'">
                                                    </div>
                                                    <div class="custom-control-inline w-50 d-flex align-items-center">
                                                        <label class="w-50 ml-0">상품유형</label>
                                                        <input type="text" class="form-control" placeholder="상품유형" aria-label="" v-model="item.한글표시사항.상품유형" style="text-align:center;"  v-b-tooltip.right="'ex) 주방용품'">
                                                    </div>
                                                    <div class="custom-control-inline w-50 d-flex align-items-center">
                                                        <label class="w-50 ml-0">사용시주의사항</label>
                                                        <input type="text" class="form-control" placeholder="사용시주의사항" aria-label="" v-model="item.한글표시사항.사용시주의사항" style="text-align:center;"  v-b-tooltip.right="'ex) 용도 이외에 사용금지'">
                                                    </div>
                                                    <div class="custom-control-inline w-50 d-flex align-items-center">
                                                        <label class="w-50 ml-0">사용기준</label>
                                                        <input type="text" class="form-control" placeholder="사용기준" aria-label="" v-model="item.한글표시사항.사용기준" style="text-align:center;"  v-b-tooltip.right="'ex) 14세 이상'">
                                                    </div>
                                                </div>

                                                <div class="col-md-12" style="display: flex">
                                                    <h6 class="mt-4 mb-1">[ 기타요청사항 ]</h6>
                                                    <div class="mt-3" style="margin-left: 8px; position: relative; top: 4px; color: #e83e8c">(예: 1688제품 겉박스 중국어 있는부분 작업 요청 내용, 실사, 사이즈, 무게, 센터 입고요청, 신발도장, 의류봉제 등, OPP/LDPE/뽁뽁이 일반검수/정밀검수, 시간이 걸리는 별도의 작업은 6000/시간의 작업비 발생)</div>
                                                </div>
                                                <div class="col-md-12">
                                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="item.요청내역.reqcontent"></textarea>
                                                </div>

                                                <div class="col-md-12 mt-2">
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch222" v-model="formData.구매취소" @change="cancelpo(formData)">
                                                        <label class="custom-control-label" for="customSwitch222">구매취소</label>
                                                    </div>
                                                    <div class="custom-control">
                                                        <textarea v-if="formData.구매취소" class="form-control" id="exampleFormControlTextarea122" v-model="formData.구매취소내용"></textarea>
                                                    </div>
                                                </div>
                                                <b-button variant="outline-primary" class="mb-1 mr-2" @click="onClickLocket(formData)" style="margin-left: 10px">
                                                    로켓배송 변경
                                                </b-button>

                                            </div>
                                            <div class="row" v-else>
                                                <div class="col-md-12">
                                                    <h6 class="mt-2 mb-1">[ 출고유형 ]</h6>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="formData.요청내역.poarrange">
                                                        <label class="custom-control-label" for="customSwitch1">발주센터/입고예정일에 따라 박스 분류</label>
                                                    </div>
                                                    <span v-if="!formData.요청내역.poarrange">※제품/발주 구분없이 적재되어 출고됩니다.</span>
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <div class="input-group" v-if="formData.요청내역.poarrange">
                                                            <input type="text" class="form-control" placeholder="발주서선택" aria-label="" v-model="formData.selectpo" style="text-align:center;">
                                                            <div class="input-group-append">
                                                                <b-dropdown class="my-class" variant="primary" type="button" text="">
                                                                    <b-dropdown-item @click="polistchange(b,formData)" v-for="b,j in formData.polists" :key="j">발주번호 : {{b.발주번호}} ({{b.발주센터}}/{{b.입고예정일}}) - {{b.sku수량}}개</b-dropdown-item>
                                                                </b-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="customRadio1" name="customRadio-3" class="custom-control-input" v-model="formData.요청내역.exportType" value="pallet">
                                                        <label class="custom-control-label" for="customRadio1">파렛트출고</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="customRadio2" name="customRadio-3" class="custom-control-input" v-model="formData.요청내역.exportType" value="box">
                                                        <label class="custom-control-label" for="customRadio2">박스출고</label>
                                                    </div>
                                                    <span>※최대 박스 사이즈는 60cm(가로) x 40cm(세로) x 50cm(높이)이며, 상황에 따라 변경될 수 있습니다.</span>
                                                </div>

                                                <div class="col-md-12 mt-4 mb-1">
                                                    <h6>[ 구매요청수량 ]</h6>
                                                    <div class="custom-control-inline">
                                                        <input type="number" :min="0" class="form-control" placeholder="구매요청수량" aria-label="" v-model="formData.구매요청수량" style="text-align:center;">
                                                    </div>
                                                </div>
                                                <div class="col-md-12" v-if="formData.구매요청수량 > formData.발주수량 && formData.발주수량 > 0 && formData.요청내역.poarrange">
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="customRadio3" name="customRadio-13" class="custom-control-input" v-model="formData.재고동봉" value=true>
                                                        <label class="custom-control-label" for="customRadio3">나머지 수량 출고</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="customRadio4" name="customRadio-13" class="custom-control-input" v-model="formData.재고동봉" value=false>
                                                        <label class="custom-control-label" for="customRadio4">나머지 수량 재고보관</label>
                                                    </div>
                                                    <span>※나머지 수량 출고 시 별도의 박스로 함께 출고됩니다.</span>
                                                </div>
                                                <div class="col-md-12 mt-4 mb-1">
                                                    <h6 v-b-tooltip.left="'해당 SKU가 2p 구성일 경우에는 2라고 작성해 주세요'">[ 판매구성수량 ]</h6>
                                                    <div class="custom-control-inline">
                                                        <input type="number" :min="0" class="form-control" placeholder="판매구성수량" aria-label="" v-model="formData.판매구성수량" style="text-align:center;">
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-4 mb-1">
                                                    <h6 v-b-tooltip.left="'통관 시 필요한 정보를 기입해주세요 ex. 신고영문명 : shoes, 신고가격(USD) : 3.5, HS-CODE : 6401.10-0000'">[ 통관정보 ]</h6>
                                                    <div class="custom-control-inline">
                                                        <input type="text" class="form-control" placeholder="신고영문명" aria-label="" v-model="formData.신고영문명" style="text-align:center;" v-b-tooltip.left="'제품의 신고영문명을 간단히 작성해주세요.'">
                                                    </div>
                                                    <div class="custom-control-inline">
                                                        <input type="number" :min="0" class="form-control" placeholder="신고가격(USD)" aria-label="" v-model="formData.신고가격" style="text-align:center;" v-b-tooltip.left="'제품의 신고가격(USD)를 작성해주세요. 원/위안화는 달러로 환산해서 작성해주세요.'">
                                                    </div>
                                                    <div class="custom-control-inline">
                                                        <input type="text" class="form-control" placeholder="HS-CODE" aria-label="" v-model="formData.HSCODE" style="text-align:center;" v-b-tooltip.left="'통관할 제품의 HS-CODE를 작성해주세요 ex : 6401.10-1000'">
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <h6 class="mt-4 mb-1">[ 원산지작업 ]</h6>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="formData.요청내역.barcode" @change="labelling(formData.요청내역)">
                                                        <label class="custom-control-label" for="customSwitch2">바코드라벨(원산지,한글표시사항 포함)</label>
                                                    </div>
                                                    <div class="custom-control custom-switch custom-control-inline" v-if="formData.요청내역.barcode">
                                                        <input type="text" class="form-control" placeholder="바코드입력" aria-label="" v-model="formData.바코드" style="text-align:center;" >
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch3" v-model="formData.요청내역.sticker">
                                                        <label class="custom-control-label" for="customSwitch3">원산지스티커</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch4" v-model="formData.요청내역.stamp">
                                                        <label class="custom-control-label" for="customSwitch4">원산지도장</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch5" v-model="formData.요청내역.tag">
                                                        <label class="custom-control-label" for="customSwitch5">원산지택총</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <h6 class="mt-4 mb-1">[ 검수 및 포장작업 ]</h6>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch6" v-model="formData.요청내역.default" @change="qadefault(formData.요청내역)">
                                                        <label class="custom-control-label" for="customSwitch6">기본검수</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch7" v-model="formData.요청내역.opp">
                                                        <label class="custom-control-label" for="customSwitch7">투명 OPP 봉투 (견적서 참조)</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch8" v-model="formData.요청내역.LDPE">
                                                        <label class="custom-control-label" for="customSwitch8">불투명 LDPE 봉투 (견적서 참조)</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch9" v-model="formData.요청내역.airpacking">
                                                        <label class="custom-control-label" for="customSwitch9">에어캡 포장 (견적서 참조)</label>
                                                    </div>
                                                    <p>※큰 부피의 제품(최대박스 사이즈의 1/5이상 사이즈)인 경우 별도 협의</p>
                                                </div>
                                                <div class="col-md-12">
                                                    <h6 class="mt-4 mb-1">[ 기타요청사항 ]</h6>
                                                </div>
                                                <div class="col-md-12">
                                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="formData.요청내역.reqcontent"></textarea>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch222" v-model="formData.구매취소" @change="cancelpo(formData)">
                                                        <label class="custom-control-label" for="customSwitch222">구매취소</label>
                                                    </div>
                                                    <div class="custom-control">
                                                        <textarea v-if="formData.구매취소" class="form-control" id="exampleFormControlTextarea122" v-model="formData.구매취소내용"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-modal>
                                    </div>
                                </template>
                            </CDataTable>
                        </div>
                        <div class="col-lg-12 mb-4 d-flex justify-content-between" v-if="showreqlist">
                            <div class="row">
                                <button type="button" class="btn btn-primary mr-2" @click="download">1688주문서 다운로드</button>
                                <button type="button" class="btn btn-secondary mr-2" @click="downloadlist">주문내역 다운로드</button>
                                <button type="button" class="btn btn-danger mr-2" v-b-toggle.my-collapse>일괄변경</button>
                            </div>

                            <div class="custom-control custom-switch custom-control-inline">
                                <input type="checkbox" class="custom-control-input" id="customSwitchre" v-model="changeoption" @change="changeoptiondone">
                                <label class="custom-control-label" for="customSwitchre">구매옵션재매칭</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <b-collapse id="my-collapse" class="mt-3">
                                <b-card title="">
                                    <div>
                                        <div class="row">
                                            <div class="col-2 custom-control">
                                                <b-form-select v-model="일괄변경" :options="wholechanges"></b-form-select>
                                            </div>
                                        </div>
                                        <div v-if="일괄변경 == '구매취소'">
                                            <textarea class="form-control ml-2 mt-2" placeholder="구매취소내용 또는 사유를 작성해 주세요." v-model="일괄구매취소내용"></textarea>
                                        </div>

                                    </div>
                                    <button type="button" class="btn btn-success ml-2 mt-2" @click="allcancelpo">적용</button>
                                </b-card>
                            </b-collapse>
                        </div>
                        <div class="d-flex justify-content-end" v-if="showreqlist">

                        </div>
                    </tab-content-item>
                </tab-content>


            </div>
        </div>
        <!-- Page end  -->
    </div>
</template>
<script>
import * as XLSX from 'xlsx';
import axios from 'axios';
import { validationMixin } from 'vuelidate'
import { required ,integer	} from 'vuelidate/lib/validators'
import CDataTable from "@/components/common/CDataTable";
// import { arrayMax } from 'highcharts';
// import { VueEditor } from 'vue2-editor'
export default {
    name:'Reqpurchase',
     mixins: [validationMixin],
    validations: {
      purchaseno: { required },
      payment: { required ,integer},
      },
    components:{
    //    VueEditor,
        CDataTable
    },
    data(){
        return{
            headers: [
                {text: '업체명', value: 'companyid', align: 'center', width: 100, isSlot: false},
                {text: '요청일', value: '요청일', align: 'center', width: 100, isSlot: true},
                {text: '요청타입', value: 'reqtype', align: 'center', width: 100, isSlot: false},
                {text: '묶음배송', value: '묶음구성여부', align: 'center', width: 100, isSlot: true},
                {text: '진행현황', value: '진행현황', align: 'center', width: 100, isSlot: false},
                {text: '제품정보', value: '상품명', align: 'left', width: 400, isSlot: true},
                {text: '구매요청수량', value: '구매요청수량', align: 'center', width: 120, isSlot: false},
                {text: '판매구성수량', value: '판매구성수량', align: 'center', width: 120, isSlot: false},
                {text: '구매링크', value: '구매링크', align: 'center', width: 100, isSlot: true},
                {text: '옵션1_중국어', value: '옵션1_중국어', align: 'center', width: 120, isSlot: false},
                {text: '옵션2_중국어', value: '옵션2_중국어', align: 'center', width: 120, isSlot: false},
                {text: '구매재고', value: '구매재고', align: 'center', width: 100, isSlot: false},
                {text: 'Action', value: 'action', align: 'center', width: 100, isSlot: true},
            ],
            tableSelectedItem: [],
            changeindexlist : [],
            changeoption : false,
            change : false,
            일괄변경 : '구매취소',
            wholechanges : [{value:'구매전',text:'구매전'},{value:'구매취소',text:'구매취소'},{value:'구매확인',text:'구매확인'}],
            일괄구매취소내용 : '',
            selectdate : 'today',
            startdate: this.getTodaywith(),
            enddate: this.getTodaywith(),
            selectoption:'요청일',
            SKUID: '',
            상품명: '',
            바코드: '',

            tobepurchase:true,

            formData: {
                상품매칭 :  [],
                옵션1_중국어 : '',
                옵션2_중국어 : '',
                구매재고 : '구매불가',
                등록이미지 :'',
                옵션이미지 : '',
                매칭상태 : '매칭필요',
                요청내역: {}
            },


            업체명:'',
            companyids:[],
            importtext:'Connect',

            total: 0,
            구매전: 0,
            발송전: 0,
            발송완료: 0,
            입고완료: 0,
            노마드클럽: 0,
            일반배송 : 0,
            구매취소: 0,
            발송지연 : 0,
            배송지연 : 0,

            showspinner: false,

            allcheckreqlists : false,
            reqlists: [],
            reqlistsinit : [],

            title:"구매대행요청",
            desc1:"쿠팡로켓 / 제트배송 / 일반LCL을 선택한 후에 양식을 다운받아 작성하여 업로드 해주세요",
            desc2:"상품DB에 SKU ID/구매링크/옵션1_중국어/옵션2_중국어를 미리 업로드해 놓을 경우 자동으로 상품이 매핑됩니다. (일반LCL제품은 10000000이하의 숫자로 SKU ID를 작성해 주세요)",
            desc3:"",

            showreqlist: false,
            showlist: false,

            checkbarcode: true,
            checktag: false,
            checkstamp: false,
            checksticker: false,

            // reqtype: "로켓배송",
            showreqrocket: false,
            showreqjet: false,
            showreqgeneral: false,
            purchaseno:'',
            payment:'',
            reqcontent:'',

            allchecked: false,
            packageMethod :'auto',
            lists: [],
            downloadlists: [],
            currentindex: '',

            uploadstatus:'excel file upload',

            CreatePo_수취인 : this.$store.state.settings[0].CreatePo_수취인,
            CreatePo_연락처 : this.$store.state.settings[0].CreatePo_연락처,
            CreatePo_주소_성 : this.$store.state.settings[0].CreatePo_주소_성,
            CreatePo_주소_시 : this.$store.state.settings[0].CreatePo_주소_시,
            CreatePo_주소_구 : this.$store.state.settings[0].CreatePo_주소_구,
            CreatePo_상세주소 : this.$store.state.settings[0].CreatePo_상세주소,
            CreatePo_주문번호 : this.$store.state.settings[0].CreatePo_주문번호,
            CreatePo_발주기준일 : this.$store.state.settings[0].CreatePo_발주기준일,

        }
    },
    created(){
        document.addEventListener.call(window, "message", event => {
            if(event.data.res && event.data.res === 'yes'){
                console.log(event.data.res);
                console.log(event.data.result[0].result);
                if(event.data.result[0].result.option1array.length == 0){
                    this.lists[this.currentindex].상품매칭.옵션1_중국어 = [];
                    this.lists[this.currentindex].옵션1_중국어 = '';
                } else {
                    this.lists[this.currentindex].상품매칭.옵션1_중국어 = event.data.result[0].result.option1array;
                }
                if(event.data.result[0].result.option2array.length == 0){
                    this.lists[this.currentindex].상품매칭.옵션2_중국어 = [];
                    this.lists[this.currentindex].옵션2_중국어 = '';
                } else {
                    this.lists[this.currentindex].상품매칭.옵션2_중국어 = event.data.result[0].result.option2array;
                }
                this.lists[this.currentindex].showspinner = false;
                this.currentindex = '';
            }
        });
    },
    methods: {
        changePackage(list){
            if(this.packageMethod == 'opp'){
                list.요청내역.opp = true;
                list.요청내역.LDPE = false;
                list.요청내역.auto = false;
                return
            }
            if(this.packageMethod == 'LDPE'){
                list.요청내역.opp = true;
                list.요청내역.LDPE = false;
                list.요청내역.auto = false;
                return
            }
            if(this.packageMethod == 'noPackage'){
                list.요청내역.opp = false;
                list.요청내역.LDPE = false;
                list.요청내역.auto = false;
                return
            }
            if(this.packageMethod == 'auto'){
                list.요청내역.opp = false;
                list.요청내역.LDPE = false;
                list.요청내역.auto = true;
                return
            }
        },
        confirmBarcode(list){
            if(list.바코드.includes('no') || list.바코드.includes('NO') || list.바코드.includes('No') || list.바코드.includes('노')){
                if(list.묶음구성여부){
                    alert('바코드 정보가 없는 경우 묶음구성 진행이 어렵습니다. 바코드를 먼저 생성해 주세요')
                    return
                }
            }
        },
        async changeoptiondone(){
            if(this.change && !this.changeoption){
                if(confirm('변경사항을 저장하시겠습니까?')){
                    this.reqlists.forEach((e,i) => {
                        if(this.changeindexlist.includes(i)){
                            e.checked = true;
                        } else {
                            e.checked = false;
                        }
                    })
                    var arr = this.reqlists.filter(e => e.checked);
                    if(confirm('구매불가는 구매취소처리하시겠습니까?')){
                        arr.forEach(e => {
                            e.진행현황 = '구매취소';
                            e.구매취소 = true;
                        })
                    }
                    var result = await this.updatereqlist(arr);
                    console.log(result);
                    this.search();
                } else {
                    this.search();
                }
            }
        },
        async allcancelpo(){
            if(this.tableSelectedItem.length == 0){
                alert('변경할 내역을 선택해 주세요')
                return
            }
            if(this.일괄변경 == '구매취소' && this.tableSelectedItem.filter(e => e.진행현황 !== '구매전' && e.진행현황 !== '구매취소').length > 0){
                if(!confirm('이미 구매한 제품이 있습니다. 그래도 구매취소처리하시겠습니까?')){
                    return
                }
            }
            this.tableSelectedItem.forEach(e => {
                e.진행현황 = this.일괄변경;
                if(this.일괄변경 == '구매취소'){
                    e.구매취소 = true;
                    e.구매취소내용 = e.일괄구매취소내용;
                } else {
                    e.구매취소 = false;
                    e.구매취소내용 = '';
                }
            })
            var arr = this.tableSelectedItem
            var result = await this.updatereqlist(arr);
            console.log(result);
            this.search();
        },
        cancelpo(list){
            if(list.구매취소){
                list.진행현황 = "구매취소"
            } else {
                list.진행현황 = "구매전"
            }
            console.log(list)
        },
        productNameWith(list){
            // if(confirm('변경된 한글표시사항 제품명에 따라 상품명도 변경하시겠습니까?')){
                list.상품명 = list.한글표시사항.제품명;
            // }
        },
        importcompanyinfo(){
            if(this.업체명){
                if(this.$store.state.purchase.length > 0 && this.업체명.toUpperCase() == this.$store.state.purchase[0].companyid.toUpperCase()){
                    this.showimportspinner = false;
                    this.importtext = 'Connected'
                    return
                }
                this.showimportspinner = true;
                axios.post('/api/logisaddimport/importcompanyinfo',{
                    companyid : this.업체명
                })
                .then( result => {
                if (result.data.요청결과 == 'success') {
                    this.importreqlists()
                    this.$store.commit('dbupdate', result.data.dbs)
                    this.importstock = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn') : [];
                    this.showimportspinner = false;
                    this.importtext = 'Connected'
                } else {
                    alert('업체 Data Import 실패')
                }
                }).catch((e)=>{
                    console.log(e);
                })
            } else {
                alert('업체ID를 기입해 주세요')
            }
		},
        polistchange(b,list){
            list.발주수량 = b.sku수량;
            list.구매요청수량 = b.sku수량;
            list.selectpo = b.발주번호;
        },
        labelling(요청내역){
            if(요청내역.barcode){
                요청내역.한글표시사항 = true
            } else {
                요청내역.한글표시사항 = false
            }
        },
        qadefault(요청내역){
            if(!요청내역.default){
                if(요청내역.barcode || 요청내역.sticker || 요청내역.stamp || 요청내역.tag){
                    alert('개별 원산지 작업을 하는 경우 기본검수는 필수항목 입니다.')
                    요청내역.default = true;
                }
            }
        },

        showModal(item) {
            this.formData = item
            // console.log('formData', this.formData)
        },
        async saverequest(item){
            const selectedIndex = this.reqlists.findIndex(i => i._id === item._id);
            if(item.진행현황 === '입고완료'){
                if(!confirm('입고가 완료된 상품은 수정이 불가능 합니다. 그래도 진행하시겠습니까?')){
                    return
                }
            }
            if(item.진행현황 === '구매전'){
                this.reqlistsinit[selectedIndex] = this.deepClone(item)
                await this.updatereqlist([item])
                this.$forceUpdate();
            } else {
                if(item['구매요청수량'] !== this.reqlistsinit[selectedIndex]['구매요청수량']){
                    if(!confirm('이미 구매 진행된 상품은 구매요청수량을 변경할 수 없습니다. 그래도 진행하시겠습니까?')){
                        return
                    }
                } else {
                    this.reqlistsinit[selectedIndex] = this.deepClone(item)
                    await this.updatereqlist([item])
                    this.$forceUpdate();
                }
            }
        },
        async updatereqlist(arr){
            if(arr.length > 0){
                axios.post('/api/reqpurchase/updatereqpurchase',arr)
                .then((res) => {
                    if(res.data.요청결과 == '변경완료'){
                        alert(res.data.요청결과)
                    } else {
                        alert(res.data.요청결과)
                        console.log(res.data)
                        return res
                    }
                })
                .catch(console.log)
            } else {
                alert('변경할 내역이 없습니다.')
            }
        },
        showtotal(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit;
                this.showreqlist = true;
            }, 10);
        },
        showa(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '구매전');
                this.showreqlist = true;
            }, 10);
        },
        showb(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '배송전');
                this.showreqlist = true;
            }, 10);
        },
        showc(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 != '구매전' && e.진행현황 != '배송전' && e.진행현황 != '발송지연' && e.진행현황 != '입고완료' && e.진행현황 != '배송지연');
                this.showreqlist = true;
            }, 10);
        },
        showd(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '입고완료');
                this.showreqlist = true;
            }, 10);
        },
        showe(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.reqtype == '제트배송');
                this.showreqlist = true;
            }, 10);
        },
        showf(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.구매취소);
                this.showreqlist = true;
            }, 10);
        },
        showg(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '발송지연');
                this.showreqlist = true;
            }, 10);
        },
        showh(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '배송지연');
                this.showreqlist = true;
            }, 10);
        },
        showi(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.reqtype !== '제트배송');
                this.showreqlist = true;
            }, 10);
        },
        showj(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '구매확인');
                this.showreqlist = true;
            }, 10);
        },
        async poconfirm(arr){
            arr.forEach(e => {
                if (e.진행현황 === '구매전') {
                    e.진행현황 = "구매확인"
                }
            })
            var result = await this.updatereqlist(arr);
            console.log(result);
            this.search();
        },
        getTimeDate(timestamp) {
            let date = new Date(timestamp);
            let year = date.getFullYear();
            let month = ("0" + (1 + date.getMonth())).slice(-2);
            let day = ("0" + date.getDate()).slice(-2);
            let hours = ("0" + date.getHours()).slice(-2);
            let minutes = ("0" + date.getMinutes()).slice(-2);
            let seconds = ("0" + date.getSeconds()).slice(-2);

            return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
        },
        download() {
            var arr = [];
            var ar = [];
            if(this.tableSelectedItem.length == 0){
                if(this.reqlists.filter(e => e.진행현황 == '구매전').length < this.reqlists.length){
                    alert('구매전인 제품들만 선택해주세요.')
                    return
                }
                if(this.reqlists.filter(e => e.companyid == this.reqlists[0].companyid).length < this.reqlists.length){
                    alert('같은 업체명만 선택해주세요')
                    return
                }
                arr = this.reqlists
            } else {
                if(this.tableSelectedItem.filter(e => e.진행현황 == '구매전').length < this.tableSelectedItem.length){
                    alert('구매전인 제품들만 선택해주세요.')
                    return
                }
                if(this.tableSelectedItem.filter(e => e.companyid == this.tableSelectedItem[0].companyid).length < this.tableSelectedItem.length){
                    alert('같은 업체명만 선택해주세요')
                    return
                }
                arr = this.tableSelectedItem
            }
            if(confirm('다운로드 내역을 구매확인으로 변경하시겠습니까?')){
                this.poconfirm(arr);
            }

            var obj = {};
            arr.forEach(e => {
                if(e.reqtype == '로켓배송'){
                    var reqtype = 'R仓库'
                } else {
                    if(e.reqtype == '제트배송'){
                        reqtype = 'Z仓库'
                    } else {
                        reqtype = 'G仓库'
                    }
                }

                obj = {
                    '订单号':e.companyid + "-" + this.getToday(),
                    '商品名称':e.상품명,
                    '商品数量(件)':e.구매요청수량 * e.판매구성수량,
                    '商品规格1（如：颜色）': e.옵션1_중국어 === undefined ? '' : e.옵션1_중국어,
                    '商品规格2（如：尺码）': e.옵션2_중국어 === undefined ? '' : e.옵션2_중국어,
                    '收件人-姓名': this.CreatePo_수취인,
                    '收件人-手机': '15650102901',
                    '收件人-省':this.CreatePo_주소_성,
                    '收件人-市':this.CreatePo_주소_시,
                    '收件人-区':this.CreatePo_주소_구,
                    '收件人-详细地址':'凤林街道海南路9号飞运' + ' ' + e.companyid.toUpperCase() + `${reqtype}(无法自提 需要送货上门的物流或快递 仓库电话13296318809)`,
                    '收货地址（省/市/区/详细地址合并的收货地址）':'',
                    '买家留言':e.SKUID,
                    '1688商品链接/1688商品id':e.구매링크.slice(30,e.구매링크.search('.html')),
                    '分销or现货（默认或者填1走分销，0走现货）':0,
                }
                ar.push(obj)
            })

            console.log(ar)

            this.downloadpo(ar)
      },
        async onClickLocket(list) {
            if (list.진행현황 !== '구매전') {
                alert('구매전인 상품만 변경 가능합니다.');
            } else {
                if (confirm('로켓배송으로 변경 하시겠습니까 ?')) {
                    try {
                        const response = await axios.post('/api/reqpurchase/changeLocket', {list})
                        console.log(response)
                        alert(response.data.요청결과)
                    } catch (error) {
                        alert('알 수 없는 error')
                        console.error(error);
                    }
                } else {
                    return false
                }
            }
        },
        search(){
            // console.log(this.업체명)
            this.showreqlist = false;
            var start = this.gettimestamp(this.startdate) - (1000*60*60*9)
            var end = this.gettimestamp(this.getdate(this.gettimestamp(this.enddate) + (1000*60*60*24))) - (1000*60*60*9) - 1
            console.log(start,end)
            axios.post('/api/reqpurchase/getliststotal',{start:start,end:end,companyid:this.업체명,tobepurchase:this.tobepurchase})
                .then((res) => {

                    this.$store.commit('reqpurchaseupdate',res.data.reqpurchase);
                    console.log(res.data.reqpurchase)
                    console.log(res.data.요청결과)

                    var arr = [];
                    res.data.reqpurchase.forEach(e => {
                        e.진행현황 = e.진행현황 ? e.진행현황 : "구매전";
                        e.구매취소 = e.구매취소 ? e.구매취소 : false;
                        e.구매취소내용 = e.구매취소내용 ? e.구매취소내용 : '';
                        e.판매구성수량 = e.판매구성수량 == '단품' ? 1 : e.판매구성수량;
                        if(e.진행현황 == '발송전'){
                            e.진행현황 = '구매완료';
                        }
                        e.checked = false;
                        arr.push(this.deepClone(e))
                    })

                    this.구매전 = arr.filter(e => e.진행현황 == '구매전').length;
                    this.발송전 = arr.filter(e => e.진행현황 == '발송전').length;//발송지연
                    this.발송완료 = arr.filter(e => e.진행현황 == '발송완료').length;
                    this.입고완료 = arr.filter(e => e.진행현황 == '입고완료').length;
                    this.노마드클럽 = arr.filter(e => e.reqtype == '제트배송').length;
                    this.일반배송 = arr.filter(e => e.reqtype !== '제트배송').length;
                    this.구매확인 = arr.filter(e => e.진행현황 == '구매확인').length;
                    this.구매취소 = arr.filter(e => e.구매취소).length;
                    this.total = arr.length;

                    this.reqlistsinit = arr;
                    this.reqlists = arr;

                    this.showreqlist = true;
                    console.log(this.reqlists)
                })
                .catch(e => console.log('err821' + e))

        },
        getdate(timestamp){
            var date = new Date(timestamp);
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },
        changedate(){
            var now
            var target
            if(this.selectdate == 'today'){
                this.startdate = this.getTodaywith();
                this.enddate = this.getTodaywith();
            } else {
                if(this.selectdate == 'week'){
                    now = new Date(this.getTodaywith());
                    target = now - 1000*60*60*24*7

                    this.startdate = this.getdate(target);
                    this.enddate = this.getTodaywith();
                } else {
                    if(this.selectdate == 'month'){
                    now = new Date(this.getTodaywith());
                    target = now - 1000*60*60*24*30
                    this.startdate = this.getdate(target);
                    this.enddate = this.getTodaywith();
                    }
                }
            }
        },
        allcheck(){
            if(this.allcheckreqlists){
                this.reqlists.forEach(e => {
                    e.checked = true;
                })
            } else {
                this.reqlists.forEach(e => {
                    e.checked = false;
                })
            }
        },
        confirmlists(){
            var arr = [];
            if(this.lists.filter(e => e.checked).length == 0){
                arr = this.lists
            } else {
                arr = this.lists.filter(e => e.checked)
            }
            if(arr.filter(e => e.구매링크 === undefined).length > 0){
                alert('구매링크를 모두 입력해 주세요')
                return
            }
            if(arr.filter(e => e.옵션1_중국어 === undefined || e.옵션2_중국어 === undefined).length > 0){
                if(confirm('매칭이 안된 옵션이 있습니다. 이대로 진행하시겠습니까?')){
                    return arr
                } else {
                    return []
                }
            }
            return arr
        },
        async requestpurchase(){
            var list = await this.confirmlists()
            if(list.length == 0){
                alert('요청할 목록이 없습니다.')
                return
            }
            var cnt = list.length;
            var arr = [];
            var obj = {};
            var now = Date.now();
            list.forEach(e => {
                obj = {
                    companyid : this.$store.state.user.companyid,
                    요청일 : now,
                    SKUID : parseInt(e.SKUID),
                    바코드 : e.바코드,
                    상품명 : e.상품명,
                    구매링크 : e.구매링크,
                    옵션1_중국어 : e.옵션1_중국어,
                    옵션2_중국어 : e.옵션2_중국어,
                    구매요청수량 : e.구매요청수량,
                    등록이미지 : e.등록이미지,
                }
                arr.push(obj);
            })
            if(confirm("총 " + cnt + "건을 구매대행 요청하시겠습니까?")){
                axios.post('/api/reqpurchase/requestpurchase',arr)
                .then((res) => {
                    this.$store.commit('reqpurchaseupdate',res.data.reqpurchase);
                    alert(res.data.요청결과)
                })
                .catch(console.log)
            }
        },
        async saveproductdb(){
            var list = await this.confirmlists()
            if(list.length == 0){
                alert('업데이트할 목록이 없습니다.')
                return
            }
            console.log(list)
            axios.post('/api/reqpurchase/updateproductdb',list)
            .then((res) => {
                this.$store.commit('productdbupdate',res.data.productdb);
                alert(res.data.요청결과)
            })
            .catch(console.log)
        },
        deleteindexfromarray(array,index){
            return array.slice(0,index).concat(array.slice(index+1))
        },
        deleteitem(index){
            if(confirm('삭제된 제품은 복구가 불가능합니다. 삭제하시겠습니까?')){
                this.showlist = false;
                setTimeout(() => {
                    this.lists = this.deleteindexfromarray(this.lists,index);
                    this.showlist = true;
                }, 1);
            }
        },
        confirmexcelDownFunc(){
            if(this.reqtype == '로켓배송'){
                this.excelDownFunc()
                return
            }
            if(this.reqtype == '제트배송'){
                this.excelDownFunc()
                return
            }
            if(this.reqtype == '일반LCL'){
                if(confirm('일반LCL에서 SKUID는 10000000 이하의 숫자로 임의로 기입하시면 됩니다.')){
                    this.excelDownFunc()
                    return
                }
            }
        },
        reset(list){
            list.옵션1_중국어 = undefined;
            list.옵션2_중국어 = undefined;
            list.상품매칭.옵션1_중국어 = [];
            list.상품매칭.옵션2_중국어 = [];
            list.구매링크 = undefined;
        },
        rematching(list){
            this.change = true;
            const selectedIndex = this.reqlists.findIndex(i => i._id === list._id);
            this.changeindexlist.push(selectedIndex);
            if(!list.구매링크){
                alert('구매링크를 입력해 주세요')
                return
            }
            list.옵션1_중국어 = undefined;
            list.옵션2_중국어 = undefined;
            list.상품매칭.옵션1_중국어 = [];
            list.상품매칭.옵션2_중국어 = [];
            this.matchoption1(selectedIndex,list.구매링크)
        },
        fileupload(){
            this.$refs.pdbupload.value = '';
            this.$refs.pdbupload.click();
        },
        option1matching(a,list){
            this.change = true;
            // this.showlist = false;
            if(a.imageUrl){
                if(!list.등록이미지){
                    list.등록이미지 = a.imageUrl;
                }
                list.옵션이미지 = a.imageUrl;
            }
            list.옵션1_중국어 = a.name;


            // if(this.checkstock){
            if(list.옵션2_중국어 == ''){
                var properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
                list.구매재고 = parseInt(list.item.skus.sku.find(e => e.properties == properties1).quantity);
                if(list.구매재고 == 0){
                    alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                } else {
                    alert('구매 가능한 재고수량 : ' + list.구매재고)
                }
                list.매칭상태 = '매칭완료';
            } else {
                if(list.옵션2_중국어 !== undefined){
                    properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
                    var properties2 = list.상품매칭.옵션2_중국어.find(e => e.name == list.옵션2_중국어).properties;
                    list.구매재고 = parseInt(list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).quantity);
                    if(list.구매재고 == 0){
                        alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                    } else {
                        alert('구매 가능한 재고수량 : ' + list.구매재고)
                    }
                    list.매칭상태 = '매칭완료';
                }
            }
            if(list.등록이미지 == '' && list.옵션이미지 == ''){
                list.등록이미지 = list.item.pic_url;
                list.옵션이미지 = list.item.pic_url;
            }
            // this.showlist = true;
            // }
            this.pricechange(list)
        },
        option2matching(a,list){
            this.change = true;
            // this.showlist = false;
            if(a.imageUrl){
                list.등록이미지 = a.imageUrl;
            }
            list.옵션2_중국어 = a.name;

            // if(this.checkstock){

            if(list.상품매칭.옵션1_중국어 && list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어)){
                var properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
                var properties2 = list.상품매칭.옵션2_중국어.find(e => e.name == list.옵션2_중국어).properties;
                list.구매재고 = parseInt(list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).quantity);
                if(list.구매재고 == 0){
                    alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                } else {
                    alert('구매 가능한 재고수량 : ' + list.구매재고)
                }
                list.매칭상태 = '매칭완료'
            }

            // }
            if(list.등록이미지 == '' && list.옵션이미지 == ''){
                list.등록이미지 = list.item.pic_url;
                list.옵션이미지 = list.item.pic_url;
            }
            // this.showlist = true;
            this.pricechange(list)
        },
        sendmessage(url){
            window.postMessage({greeting: "importproductinfo",url:url},"*",)
        },
        async matchoption1(index,구매링크){
            this.reqlists[index].showspinner = true;
            this.reqlists[index].구매링크 = 구매링크;
            this.currentindex = index;
            console.log(구매링크)
            // setTimeout(() => {this.sendmessage(구매링크);},1)
            var newObj = await this.confirmreq(this.reqlists[index]);
            // console.log(newObj)
            // this.showlist = false
            this.reqlists[index] = newObj;
            console.log(this.reqlists[this.currentindex])
            // this.showlist = true


            // axios.post('/api/reqpurchase/geturlinfo',{
            //     url : 구매링크
            // })
            // .then((res) => {
            //     if(res.data.요청결과 == 'success'){
            //         console.log(res.data.result)
            //         var obj = {}
            //         res.data.result.option1array.forEach(e => {
            //             obj = {
            //                 image : e.imageUrl,
            //                 optionname : e.name,
            //             }
            //             list.상품매칭.옵션1_중국어.push(obj)
            //         })
            //         res.data.result.option2array.forEach(e => {
            //             obj = {
            //                 image : e.imageUrl,
            //                 optionname : e.name,
            //             }
            //             list.상품매칭.옵션1_중국어.push(obj)
            //         })

            //     } else {
            //         alert('error777 :' + res.data.요청결과)
            //     }
            // })
            // .catch(console.log)
        },
        nopo(구매링크){
            this.reqlists.forEach((obj,i) => {
                if(obj.구매링크 == 구매링크){
                    obj.상품매칭.옵션1_중국어 = [];
                    obj.상품매칭.옵션2_중국어 = [];
                    obj.옵션1_중국어 = '';
                    obj.옵션2_중국어 = '';
                    obj.구매재고 = '구매불가';
                    obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : '';
                    obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : '';
                    obj.매칭상태 = '매칭필요';
                    obj.showspinner = false;
                    this.changeindexlist.push(i)
                }
            })
        },
        async confirmreq(obj){
            if(!obj.구매링크 || obj.구매링크 == '사입'){
                obj.showspinner = false;
                return obj
            }
            obj.구매링크init = obj.구매링크;
            obj.매칭상태 = '매칭필요'
            var item = await this.getProductInfo(obj.구매링크);
            console.log(item)
            obj.item = item;

            if(item.format_check == 'fail'){
                obj.상품매칭.옵션1_중국어 = [];
                obj.상품매칭.옵션2_중국어 = [];
                obj.옵션1_중국어 = '';
                obj.옵션2_중국어 = '';
                obj.구매재고 = '구매불가';
                obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : item.pic_url;
                obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : item.pic_url;
                obj.매칭상태 = '매칭필요';
                obj.showspinner = false;
                if(confirm('해당제품은 구매불가입니다. 같은 링크를 모두 구매불가 처리하시겠습니까?')){
                    this.nopo(obj.구매링크);
                }
                return obj
            }

            var option1array = [];
            var option2array = [];
            if(item.props_list && Object.keys(item.props_list).length > 0){
                item.skus.sku.forEach(e => {
                    if(e.properties.includes(";")){
                        var properties1 = e.properties.split(';')[0];
                        var properties2 = e.properties.split(';')[1];
                        if(!option1array.find(ele => ele.properties == properties1)){
                            option1array.push({
                                properties : properties1,
                                name : e.properties_name.split(";" + properties2 + ':')[0].replace(properties1 + ":",""),
                                imageUrl : item.props_img[properties1] ? item.props_img[properties1] : '',
                            })
                        }
                        if(!option2array.find(ele => ele.properties == properties2)){
                            option2array.push({
                                properties : properties2,
                                name : e.properties_name.split(";" + properties2 + ':')[1],
                                imageUrl : item.props_img[properties2] ? item.props_img[properties2] : '',
                            })
                        }
                    } else {
                        option1array.push({
                            properties : e.properties,
                            name : e.properties_name.replace(e.properties + ":",""),
                            imageUrl : item.props_img[e.properties] ? item.props_img[e.properties] : '',
                        })
                    }
                })



                // Object.keys(item.props_list).forEach(e => {
                //     if(e[0] == '0'){
                //         option1array.push({
                //             properties : e,
                //             name : item.props_list[e].split(":")[1],
                //             imageUrl : item.props_img[e] ? item.props_img[e] : '',
                //         })
                //     }
                //     if(e[0] == '1'){
                //         option2array.push({
                //             properties : e,
                //             name : item.props_list[e].split(":")[1],
                //             imageUrl : item.props_img[e] ? item.props_img[e] : '',
                //         })
                //     }
                // })
            }

            if(option1array.length == 0){
                obj.상품매칭.옵션1_중국어 = [];
                obj.상품매칭.옵션2_중국어 = [];
                obj.옵션1_중국어 = '';
                obj.옵션2_중국어 = '';
                obj.구매재고 = parseInt(item.num);
                obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : item.pic_url;
                obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : item.pic_url;
                obj.매칭상태 = '매칭완료'
            } else {
                if(option2array.length == 0){
                    var imgUrl = '';
                    if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                        obj.옵션1_중국어 = undefined;
                        obj.옵션이미지 = undefined;
                        obj.구매재고 = 0;
                    } else {
                        var option1properties = option1array.find(e => e.name == obj.옵션1_중국어).properties;
                        obj.구매재고 = parseInt(item.skus.sku.find(e => e.properties == option1properties).quantity);
                        if(item.prop_imgs.prop_img.length > 0){
                            imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option1properties).url;
                        }
                        obj.옵션이미지 = imgUrl;
                        obj.매칭상태 = '매칭완료'
                    }
                    obj.상품매칭.옵션1_중국어 = option1array;
                    obj.상품매칭.옵션2_중국어 = [];
                    obj.옵션2_중국어 = '';
                    obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : imgUrl;
                } else {
                    imgUrl = '';
                    if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                        obj.옵션1_중국어 = undefined;
                        obj.옵션이미지 = undefined;
                        obj.구매재고 = 0;
                    } else {
                        option1properties = option1array.find(e => e.name == obj.옵션1_중국어).properties;
                        if(item.prop_imgs.prop_img && item.prop_imgs.prop_img.find(e => e.properties == option1properties)){
                            imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option1properties).url;
                        }
                        obj.옵션이미지 = imgUrl;
                        if(!option2array.find(e => e.name == obj.옵션2_중국어)){
                            obj.옵션2_중국어 = undefined;
                            obj.구매재고 = 0;
                        } else {
                            var option2properties = option2array.find(e => e.name == obj.옵션2_중국어).properties;
                            obj.구매재고 = parseInt(item.skus.sku.find(e => e.properties == (option1properties + ';' + option2properties)).quantity);
                            if(item.prop_imgs.prop_img && item.prop_imgs.prop_img.find(e => e.properties == option2properties)){
                                imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option2properties).url;
                            }
                            obj.옵션이미지 = imgUrl;
                            obj.매칭상태 = '매칭완료'
                        }
                    }
                    obj.상품매칭.옵션1_중국어 = option1array;
                    obj.상품매칭.옵션2_중국어 = option2array;
                    obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : imgUrl;
                }
            }
            if(!obj.등록이미지 && !option1array.find(e => e.imageUrl) && !option1array.find(e => e.imageUrl)){
                obj.등록이미지 = item.pic_url;
            }
            this.pricechange(obj);
            obj.showspinner = false;
            return obj
        },
        async getProductInfo(구매링크){
            var productNo = 구매링크.slice(구매링크.search('offer/')+6,구매링크.search('.html'));
            console.log(productNo)
            try{
                var res = await axios.post('/api/reqpurchase/getProductInfo',[productNo])
                return res.data.result[0].item
            }catch(e){
                console.log('err821' + e);
                return false
            }
        },
        pricechange(obj){
            this.change = true;
            if(obj.매칭상태 == '매칭필요'){
                obj.구매가격 = '';
                return
            }
            if(obj.item && obj.구매요청수량){
                var option1array = [];
                var option2array = [];
                var item = obj.item;
                if(item.props_list && Object.keys(item.props_list).length > 0){
                    Object.keys(item.props_list).forEach(e => {
                        if(e[0] == '0'){
                            option1array.push({
                                properties : e,
                                name : item.props_list[e].split(":")[1],
                                imageUrl : item.props_img[e] ? item.props_img[e] : '',
                            })
                        }
                        if(e[0] == '1'){
                            option2array.push({
                                properties : e,
                                name : item.props_list[e].split(":")[1],
                                imageUrl : item.props_img[e] ? item.props_img[e] : '',
                            })
                        }
                    })
                }

                if(option1array.length == 0){
                    if(item.priceRange){
                        for(var i=0;i<item.priceRange.length;i++){
                            obj.구매가격 = parseFloat(item.priceRange[i][1]);
                            if(parseInt(item.priceRange[i][0]) > obj.구매요청수량){
                                break
                            }
                        }
                    } else {
                        obj.구매가격 = parseInt(item.price);
                    }
                } else {
                    if(option2array.length == 0){
                        if(item.priceRange){
                            for(i=0;i<item.priceRange.length;i++){
                                obj.구매가격 = parseFloat(item.priceRange[i][1]);
                                if(parseInt(item.priceRange[i][0]) > obj.구매요청수량){
                                    break
                                }
                            }
                        } else {
                            obj.구매가격 = parseInt(item.price);
                        }
                    } else {
                        if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                            obj.구매가격 = '';
                        } else {
                            if(!option2array.find(e => e.name == obj.옵션2_중국어)){
                                obj.구매가격 = '';
                            } else {
                                var option1properties = option1array.find(e => e.name == obj.옵션1_중국어).properties;
                                var option2properties = option2array.find(e => e.name == obj.옵션2_중국어).properties;
                                obj.구매가격 = parseInt(item.skus.sku.find(e => e.properties == (option1properties + ';' + option2properties)).price);
                            }
                        }
                    }
                }
            }
        },
        matchclass(a){
            if(a == '매칭완료'){
                return 'badge badge-success';
            } else {
                return 'badge badge-warning';
            }
        },
        excelDownFunc(){
            var arr = [];
            var myHeader = ["SKUID","구매요청수량"];

            var dataWS = XLSX.utils.json_to_sheet(arr, {header : myHeader});

            const range = XLSX.utils.decode_range(dataWS['!ref']);
            range.e['c'] = myHeader.length - 1;
            dataWS['!ref'] = XLSX.utils.encode_range(range);

            var wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, dataWS, '구매대행요청양식');
            // 엑셀 파일을 내보낸다.
            XLSX.writeFile(wb, '구매대행요청양식.xlsx');
        },
        // uploadfile(event) {
        //     var obj = {};
        //     var ob = {};
        //     var op1 = '';
        //     var op2 = '';
        //     this.showlist = false;
        //     this.lists = [];
        //     // var 주문번호,판매처,배송단가,할인단가,운송장번호,주문날짜,주문상태;
        //     setTimeout(() => {
        //         const file = event.target.files[0];
        //         let reader = new FileReader();
        //         reader.onload = (e) => {
        //             // let data = reader.result;
        //             let data = e.target.result;
        //             let workbook = XLSX.read(data, {type: 'binary'});
        //             workbook.SheetNames.forEach(sheetName => {
        //                 const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        //                 console.log(roa)
        //                 if(this.reqtype == '일반LCL'){
        //                     roa.forEach(e => {
        //                         if(this.$store.state.productdb.find(ele => parseInt(ele['SKU ID']) == parseInt(e.SKUID))){
        //                             ob = this.$store.state.productdb.find(ele => parseInt(ele['SKU ID']) == parseInt(e.SKUID))
        //                             // console.log(e)
        //                             // console.log(ob)
        //                             if(ob.옵션1_중국어 || ob.옵션1_중국어 == ''){
        //                                 op1 = ob.옵션1_중국어
        //                             } else {
        //                                 op1 = undefined
        //                             }
        //                             if(ob.옵션2_중국어 || ob.옵션2_중국어 == ''){
        //                                 op2 = ob.옵션2_중국어
        //                             } else {
        //                                 op2 = undefined
        //                             }
        //                             obj = {
        //                                 checked : false,
        //                                 showspinner : false,
        //                                 SKUID : e.SKUID,
        //                                 바코드 : ob.바코드 ? ob.바코드 : '',
        //                                 상품명 : ob.상품명,
        //                                 구매링크 : ob.구매링크 ? ob.구매링크 : undefined,
        //                                 옵션1_중국어 : op1,
        //                                 옵션2_중국어 : op2,
        //                                 매칭상태 : ob.구매링크 !== undefined || op1 !== undefined || op2 !== undefined ? '매칭완료' : '매칭필요',
        //                                 상품매칭 : {
        //                                     옵션1_중국어 : [],
        //                                     옵션2_중국어 : [],
        //                                 },
        //                                 구매요청수량 : e.구매요청수량,
        //                                 등록이미지 : ob.등록이미지
        //                             }
        //                             this.lists.push(obj)
        //                         } else {
        //                             if(e.옵션1_중국어 || e.옵션1_중국어 == ''){
        //                                 op1 = e.옵션1_중국어
        //                             } else {
        //                                 op1 = undefined
        //                             }
        //                             if(e.옵션2_중국어 || e.옵션2_중국어 == ''){
        //                                 op2 = e.옵션2_중국어
        //                             } else {
        //                                 op2 = undefined
        //                             }
        //                             obj = {
        //                                 checked : false,
        //                                 showspinner : false,
        //                                 SKUID : e.SKUID,
        //                                 바코드 : e.바코드 ? e.바코드 : '',
        //                                 상품명 : e.상품명,
        //                                 구매링크 : e.구매링크 ? e.구매링크 : undefined,
        //                                 옵션1_중국어 : op1,
        //                                 옵션2_중국어 : op2,
        //                                 매칭상태 : e.구매링크 !== undefined || op1 !== undefined || op2 !== undefined ? '매칭완료' : '매칭필요',
        //                                 상품매칭 : {
        //                                     옵션1_중국어 : [],
        //                                     옵션2_중국어 : [],
        //                                 },
        //                                 구매요청수량 : e.구매요청수량,
        //                                 등록이미지 : e.등록이미지
        //                             }
        //                             this.lists.push(obj)
        //                         }
        //                     })
        //                 } else {
        //                     roa.forEach(e => {
        //                         if(this.$store.state.productdb.find(ele => parseInt(ele['SKU ID']) == parseInt(e.SKUID))){
        //                             ob = this.$store.state.productdb.find(ele => parseInt(ele['SKU ID']) == parseInt(e.SKUID))
        //                             // console.log(e)
        //                             // console.log(ob)
        //                             if(ob.옵션1_중국어 || ob.옵션1_중국어 == ''){
        //                                 op1 = ob.옵션1_중국어
        //                             } else {
        //                                 op1 = undefined
        //                             }
        //                             if(ob.옵션2_중국어 || ob.옵션2_중국어 == ''){
        //                                 op2 = ob.옵션2_중국어
        //                             } else {
        //                                 op2 = undefined
        //                             }
        //                             obj = {
        //                                 checked : false,
        //                                 showspinner : false,
        //                                 SKUID : e.SKUID,
        //                                 바코드 : ob.바코드,
        //                                 상품명 : ob.상품명,
        //                                 구매링크 : ob.구매링크 ? ob.구매링크 : undefined,
        //                                 옵션1_중국어 : op1,
        //                                 옵션2_중국어 : op2,
        //                                 매칭상태 : ob.구매링크 !== undefined || op1 !== undefined || op2 !== undefined ? '매칭완료' : '매칭필요',
        //                                 상품매칭 : {
        //                                     옵션1_중국어 : [],
        //                                     옵션2_중국어 : [],
        //                                 },
        //                                 구매요청수량 : e.구매요청수량,
        //                                 등록이미지 : ob.등록이미지
        //                             }
        //                             // console.log(obj)
        //                             this.lists.push(obj)
        //                         } else {
        //                             alert('로켓/제트 상품은 상품DB를 먼저 등록해주세요')
        //                             return
        //                         }
        //                     })
        //                 }
        //                 this.showlist = true;
        //             });
        //         };
        //         reader.readAsBinaryString(file);
        //     }, 10);
        // },
        modalID(index) {
            // console.log(index);
            return 'modal' + index
        },

		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
        deletelist(index){
            var newarr = [];
            var delarr = [];
            this.reqlists.forEach((e,i) => {
                if(i != index){
                    newarr.push(this.deepClone(e))
                } else {
                    delarr.push(this.deepClone(e))
                }
            })
            this.reqlists = newarr;
            if(confirm('삭제 후에는 복구가 불가능합니다. 그래도 삭제하시겠습니까?')){
                this.deletereqlists(delarr);
            }
        },
        async deletereqlists(delarr){
            axios.post('/api/reqpurchase/deletereqpurchase',delarr)
            .then((res) => {
                if(res.data.요청결과 == '삭제완료'){
                    alert(res.data.요청결과)
                } else {
                    alert(res.data.요청결과)
                    console.log(res.data)
                }
            })
            .catch(console.log)
        },
        deletedetail(발주번호,SKUID){
            var newarr = [];
            var newobj = {};
            this.lists.forEach(e => {
                if(e.발주번호 != 발주번호){
                    newarr.push(e);
                } else {
                    newobj = this.deepClone(e);
                    newobj.발주세부내역 = [];
                    e.발주세부내역.forEach(ele => {
                        if(ele.SKUID != SKUID){
                            newobj.발주세부내역.push(ele);
                        }
                    });
                    newarr.push(newobj);
                }
            })
            this.lists = newarr;
        },
        allchecklist(allchecked){
            if(allchecked == true){
                this.lists.forEach(e => {
                    e.checked = true;
                })
            } else {
                this.lists.forEach(e => {
                    e.checked = false;
                })
            }
        },
        allcheckdetaillist(allcheckdetail){
            if(allcheckdetail == true){
                this.detaillists.forEach(e => {
                    e.checked = true;
                })
            } else {
                this.detaillists.forEach(e => {
                    e.checked = false;
                })
            }
        },
        getTodaywith(){
            var date = new Date();
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },
        getToday(){
            var date = new Date();
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + month + day;
        },
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime() / 1000
        },
        gettimestamp(date){
            return new Date(date).getTime()
        },
        downloadlist(){
            var arr = [];
            this.reqlists.forEach(e => {
                arr.push({
                    업체명 : e.companyid,
                    요청일 : this.getdate(e.요청일),
                    상품명 : e.상품명,
                    SKUID : e.SKUID,
                    바코드 : e.바코드,
                    구매요청수량 : parseInt(e.구매요청수량),
                    판매구성수량: parseInt(e.판매구성수량),
                    구매링크 : e.구매링크,
                    옵션1_중국어 : e.옵션1_중국어,
                    옵션2_중국어 : e.옵션2_중국어,
                    배치발주번호 : e.selectpo,
                    출고방법 : e.요청내역.exportType,
                    목적지 : e.요청내역.destination ? e.요청내역.destination : "coupang",
                    신고영문명 : e.신고영문명,
                    신고가격 : e.신고가격,
                    HSCODE : e.HSCODE,

                    "기본검수/분류/포장" : e.요청내역.default,
                    바코드라벨 : e.요청내역.barcode,

                    포장방법위임 : e.요청내역.auto ? "O" : "",
                    LDPE : e.요청내역.LDPE ? "O" : "",
                    OPP : e.요청내역.OPP ? "O" : "",
                    재포장생략 : (e.요청내역.auto == false && !e.요청내역.LDPE && !e.요청내역.OPP) ? "O" : "",

                    원산지스티커 : e.요청내역.sticker ? "O" : "",
                    라벨봉제 : e.요청내역.sewing ? "O" : "",
                    도장 : e.요청내역.stamp ? "O" : "",
                    택총 : e.요청내역.tag ? "O" : "",
                    에어캡포장 : e.요청내역.airpacking ? "O" : "",
                    중국어마스킹 : e.요청내역.masking ? "O" : "",

                    협의단가: e.협의단가,
                    총금액: e.요청내역.총금액,
                    협의ID: e.협의ID,

                    기타요청사항 : e.요청내역.reqcontent,
                    이미지링크 : e.등록이미지,
                })
            })
            const workBook = XLSX.utils.book_new()
            const workSheet = XLSX.utils.json_to_sheet(arr)
            XLSX.utils.book_append_sheet(workBook, workSheet, '주문내역')
            XLSX.writeFile(workBook, '주문내역' + '_' + this.getToday() + '.xlsx')
        },
        downloadpo(arr){
            if(arr.length > 0){
                var createpo = [];
                var totalpo = arr;
                totalpo.forEach((e,i) => {
                    e.index = i;
                })
                //1688id순으로 정렬 (중국내배송비최소화)
                totalpo.sort(function(a,b){
                    if (a['商品规格1（如：颜色）'] < b['商品规格1（如：颜色）']){
                        return -1;
                    }
                    if (a['商品规格1（如：颜色）'] > b['商品规格1（如：颜色）']){
                        return 1;
                    }
                    return 0;
                })
                totalpo.sort(function(a,b){
                    if (a['商品规格2（如：尺码）'] < b['商品规格2（如：尺码）']){
                        return -1;
                    }
                    if (a['商品规格2（如：尺码）'] > b['商品规格2（如：尺码）']){
                        return 1;
                    }
                    return 0;
                })
                totalpo.sort(function(a,b){
                    if (a['商品名称'] < b['商品名称']){
                        return -1;
                    }
                    if (a['商品名称'] > b['商品名称']){
                        return 1;
                    }
                    return 0;
                })
                totalpo.sort(function(a,b){
                    if (a['1688商品链接/1688商品id'] < b['1688商品链接/1688商品id']){
                        return -1;
                    }
                    if (a['1688商品链接/1688商品id'] > b['1688商品链接/1688商品id']){
                        return 1;
                    }
                    return 0;
                })

                //동일제품 1688주문번호에 구분번호추가 (발주번호 구분)
                var ct = 1;

                for(var i=1;i<totalpo.length;i++){
                    if(
                        ((totalpo[i]['商品规格1（如：颜色）'] && totalpo[i-1]['商品规格1（如：颜色）']) ? (totalpo[i]['商品规格1（如：颜色）'] == totalpo[i-1]['商品规格1（如：颜色）']) : true) &&
                        ((totalpo[i]['商品规格2（如：尺码）'] && totalpo[i-1]['商品规格2（如：尺码）']) ? (totalpo[i]['商品规格2（如：尺码）'] == totalpo[i-1]['商品规格2（如：尺码）']) : true) &&
                        (totalpo[i]['商品名称'] == totalpo[i-1]['商品名称']) &&
                        (totalpo[i]['1688商品链接/1688商品id'] == totalpo[i-1]['1688商品链接/1688商品id'])
                    ){
                        totalpo[i]['订单号'] += ct.toString();
                        ct++
                    } else {
                        ct = 1;
                    }
                }

                totalpo.sort(function(a,b){
                    if (a.index < b.index){
                        return -1;
                    }
                    if (a.index > b.index){
                        return 1;
                    }
                    return 0;
                })

                totalpo.sort(function(a,b){
                    if (a['1688商品链接/1688商品id'] < b['1688商品链接/1688商品id']){
                        return -1;
                    }
                    if (a['1688商品链接/1688商品id'] > b['1688商品链接/1688商品id']){
                        return 1;
                    }
                    return 0;
                })

                totalpo.sort(function(a,b){
                    if (a['订单号'] < b['订单号']){
                        return -1;
                    }
                    if (a['订单号'] > b['订单号']){
                        return 1;
                    }
                    return 0;
                })


                //1688id순으로 정렬 (중국내배송비최소화)
                // totalpo.sort(function(a,b){
                //     if (a.판매처 < b.판매처){
                //         return -1;
                //     }
                //     if (a.판매처 > b.판매처){
                //         return 1;
                //     }
                //     return 0;
                // })


                //1688po를 100개씩 잘라서 createpo array에 저장
                console.log(totalpo)
                var j
                var cnt = totalpo.length;

                for(j=0;j<cnt;j++){
                    if(totalpo.length < 100){
                        createpo.push(totalpo)
                        break
                    }
                    for(i=100;i>0;i--){
                        if(totalpo[i][['1688商品链接/1688商品id']] != totalpo[i-1][['1688商品链接/1688商品id']]){
                            console.log(totalpo.slice(0,i))
                            createpo.push(totalpo.slice(0,i))
                            totalpo = totalpo.slice(i,totalpo.length)
                            console.log(totalpo)
                            break
                        } else {
                            if(i==1){
                                createpo.push(totalpo.slice(0,100))
                                totalpo = totalpo.slice(100,totalpo.length)
                            }
                        }
                    }
                }

                //test
                // const workBook = XLSX.utils.book_new()
                // const workSheet1 = XLSX.utils.json_to_sheet(totalpo)
                // const workSheet2 = XLSX.utils.json_to_sheet(matchpo)
                // XLSX.utils.book_append_sheet(workBook, workSheet1, '1688주문서')
                // XLSX.utils.book_append_sheet(workBook, workSheet2, 'matching')
                // XLSX.writeFile(workBook, '1688주문서' + '_' + this.getToday() + '.xlsx')


                //다운로드
                for(i=0;i<createpo.length;i++){
                    const workBook = XLSX.utils.book_new()
                    const workSheet = XLSX.utils.json_to_sheet(createpo[i])
                    XLSX.utils.book_append_sheet(workBook, workSheet, '1688주문서')
                    XLSX.writeFile(workBook, '1688주문서' + '_' + this.getToday() + '_' + i.toString() + '.xlsx')
                }
            } else {
                alert('생성할 주문서가 없습니다.')
            }
        },

        savesetting() {
            var payload = {
                companyid : this.$store.state.user.companyid,
                CreatePo_수취인 : this.CreatePo_수취인,
                CreatePo_연락처 : this.CreatePo_연락처,
                CreatePo_주소_성 : this.CreatePo_주소_성,
                CreatePo_주소_시 : this.CreatePo_주소_시,
                CreatePo_주소_구 : this.CreatePo_주소_구,
                CreatePo_상세주소 : this.CreatePo_상세주소,
                CreatePo_주문번호 : this.CreatePo_주문번호,
                CreatePo_발주기준일 : this.CreatePo_발주기준일
            };

            this.$store.commit('settingsupdate', payload)
            console.log(this.$store.state.settings)
            axios.post('/api/addpurchase/addsettings',payload)
            .then((res) => {alert(res.data.요청결과)})
        },
        importcompanyids(){
			axios.post('/api/getcompanyids',{})
            .then( result => {
				if (result.data.요청결과 == 'success'){
					result.data.result.forEach(e => {
                        if(e.auth.showimport){
                            if(!this.companyids.includes(e.companyid)){
                                this.companyids.push(e.companyid);
                            }
                        }
					})
                    console.log(this.companyids)
				} else {
					alert(result.data.사유)
				}
            }).catch((e)=>{
                alert('companyid 가져오기 실패 (1413)')
                console.log(e);
                return true
            })
		},
    },
    importtextchange(event){
        this.importtext = 'Connect';
        if(event.keyCode === 13 && this.업체명 != ''){
            this.importcompanyinfo()
        }
    },
    mounted() {
        this.importcompanyids()
    }
}
</script>
<style>
.my-class .dropdown-menu {
transform: translate3d(0px, -30px, 0px) !important;
max-height: 100px;
width:300px;
z-index:10000;
overflow-y: auto;
}
#quill-container{
    height: 50px;
}
#quill-container .ql-editor{
    min-height: unset;
}

.modal-dialog {
    max-width: 90%;
    position: absolute;

    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}



</style>
